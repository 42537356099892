import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Iframe from "react-iframe";
import { ApiHook } from "../../hooks/apiHook";

const ZohoSignComponent = ({ esignUrl, handleNext, verifyDetails }) => {
    const [signStatus, setSignStatus] = useState("pending");
    const [embedUrl, setEmbedUrl] = useState(esignUrl);
    const [isSigningComplete, setIsSigningComplete] = useState(false);
    const [polling, setPolling] = useState(false);
    const iframeRef = useRef(null);

    useEffect(() => {
        setEmbedUrl(esignUrl);
    }, [esignUrl]);
    useEffect(() => {
        let intervelId = null;
        let timeOutId = null;
        if (polling && !isSigningComplete) {
            intervelId = setInterval(() => {
                verifyEsignStatus.mutate(verifyDetails, {
                    onSuccess: (res) => {
                        if (res.status) {
                            setPolling(false);
                            setIsSigningComplete(true);
                            setSignStatus("verified");
                            handleNext();
                        }
                    },
                });
            }, 5000);
            timeOutId = setTimeout(() => {
                clearInterval(intervelId);
                setPolling(false);
                setSignStatus("pending");
            }, 60000);
        }

        return () => {
            clearInterval(intervelId);
            clearTimeout(timeOutId);
        };
    }, [polling, isSigningComplete]);

    const handleIframeLoad = () => {
        // You can add any additional logic here when the iframe loads
        console.log("Zoho Sign iframe loaded");
    };
    const verifyEsignStatus = ApiHook.CallVerifyEsign();

    const handleVerify = () => {
        setSignStatus("verifying");
        setPolling(true);
    };
    return (
        <>
            <div className="register-terms-container">
                <div
                    className="register-terms-container-header"
                    style={{ padding: "15px 0 0 0" }}
                >
                    <div
                        className="common-div-header gta-b my-0 py-0 mb-0-imort"
                        style={{ color: "#fff" }}
                    >
                        IBE ELECTRONIC SIGNATURE VERBIAGE
                    </div>
                </div>
                <div className="register-terms-container-body">
                    {embedUrl && embedUrl != "" && (
                        <iframe
                            ref={iframeRef}
                            src={embedUrl}
                            name="SignForm"
                            width="80%"
                            height="600vh"
                            onLoad={handleIframeLoad}
                        />
                    )}
                    {(!embedUrl || embedUrl === "") && (
                        <div className="zoho_loading_page">
                            <div>
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>{"Loading Digital Sign Form..."}</div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="register-terms-container-footer">
                    {polling
                        ? "Esign verification in progress. Please wait a moment while we complete the process."
                        : "Please click the 'VERIFY' button after signing to check the status and verify it in order to proceed with the further registration process."}
                </div>
                <button
                    className="common-button"
                    style={{ maxWidth: "500px" }}
                    onClick={handleVerify}
                    disabled={signStatus === "verifying"}
                >
                    {signStatus === "pending"
                        ? "PROCEED"
                        : signStatus === "verifying"
                        ? "VERIFYING"
                        : "SUBMIT"}
                </button>
            </div>
        </>
    );
};

export default ZohoSignComponent;
