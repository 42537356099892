import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import "../style.css";

function ReplicaLayout({ children }) {
    const params = useParams();

    if (params.username) {
        localStorage.setItem("referralId", params.username);
    }

    return (
        <div>
            {/* ---------------------------------------- Set child components here -------------------------------------- */}

            {children}

            {/* --------------------------------------------------------------------------------------------------------- */}
        </div>
    );
}

export default ReplicaLayout;

// import React, { useEffect, useState } from "react";
// import { useLocation, useNavigate, useParams } from "react-router";
// import "../style.css";
// import { Link, NavLink } from "react-router-dom";
// import TermsAndConditionReplicaModal from "../components/Common/modals/ReplicaTerms";
// import PolicyReplica from "../components/Common/modals/ReplicaPolicy";
// import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
// import { format } from "date-fns";

// function ReplicaLayout({ children }) {
//     const params = useParams();

//     const companyDetails = useSelector(
//         (state) => state.replica?.companyDetails
//     );
//     const favicon = document.getElementById("dynamic-favicon");
//     if (favicon) {
//         favicon.href = companyDetails?.favicon;
//     }

//     // ---------------------------------------- API ----------------------------------------------

//     if (params.username) {
//         localStorage.setItem("referralId", params.username);
//     }

//     // --------------------------------------- Function -----------------------------------------

//     return (
//         <div className="" id="">
//             {/* ---------------------------------------- Set child components here -------------------------------------- */}

//             {children}

//             {/* --------------------------------------------------------------------------------------------------------- */}
//         </div>
//     );
// }

// export default ReplicaLayout;
