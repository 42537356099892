import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import UpgradeComponents from "../../components/upgrade/UpgradeComponents";
import { ApiHook } from "../../hooks/apiHook";
import UpgradePaymentModal from "../../components/upgrade/UpgradePaymentModal";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import UpgradeProducts from "./UpgradeProducts";
import { useForm } from "react-hook-form";
import UpgradeCheckOut from "./UpgradeCheckout";

const Upgrade = () => {
    const { t } = useTranslation();

    const [activeStep, setActiveStep] = useState(1);
    const [conversionFactor, setConversionFactor] = useState({
        symbolLeft: "$",
        value: 1,
    });

    const [courierType, setCourierType] = useState("");
    const [options1Active, setOptions1Active] = useState(false);
    const [errorsMessage, setErrorMessage] = useState({
        repurchaseError: "",
        courierError: "",
    });

    const productsList = ApiHook.CallGetUpgradeProducts();
    useEffect(() => {
        setConversionFactor(
            productsList?.userCountry.toLowerCase() === "india"
                ? {
                      symbolLeft: productsList?.activeCurrencies["INR"]?.symbol,
                      value: productsList?.activeCurrencies["INR"]?.value,
                  }
                : productsList?.userCountry.toLowerCase() === "bangladesh"
                ? {
                      symbolLeft: productsList?.activeCurrencies["BDT"]?.symbol,
                      value: productsList?.activeCurrencies["BDT"]?.value,
                  }
                : {
                      symbolLeft: productsList?.activeCurrencies["USD"]?.symbol,
                      value: productsList?.activeCurrencies["USD"]?.value,
                  }
        );
    }, [productsList]);

    const [cartData, setCartData] = useState({});
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalPv, setTotalPv] = useState(0);
    const [gst, setGst] = useState(0);
    const [totalShippingCharge, setTotalShippingCharge] = useState(0);

    const handleGstCalc = async () => {
        if (Object.keys(cartData)?.length !== 0) {
            var tempGst = 0;

            Object.entries(cartData)?.map((item) => {
                if (tempGst < item[1]?.gst * 0.01 && item[1]?.qty > 0) {
                    tempGst = item[1]?.gst * 0.01;
                }
            });
            setGst(tempGst);
            handleShippingChargeCalc(tempGst);
        }
    };

    const handleShippingChargeCalc = async (gst) => {
        if (Object.keys(cartData)?.length !== 0) {
            if (courierType === "Courier") {
                var netWeight = 0;

                Object.entries(cartData)?.map((item) => {
                    netWeight +=
                        Number(item[1]?.weight) * Number(item[1]?.qty) ?? 0;
                });
                setTotalShippingCharge(
                    Math.ceil(
                        netWeight /
                            Number(productsList?.courierBaseCharge?.baseVolume)
                    ) *
                        Number(productsList?.courierBaseCharge?.basePrice) *
                        (1 + gst)
                );
            } else {
                setTotalShippingCharge(0);
            }
        }
    };

    return (
        <div
            className="container-bg-design-container-flex"
            style={
                activeStep === 1
                    ? { height: "100vh", backgroundColor: "#fff" }
                    : { height: "100vh" }
            }
        >
            <div className="container-bg-design repurchase-cart-in-container">
                {/* <div className="repurchase-cart-in-head">
                IBE UPGRADE SIGNUP SHOPPING CART IN
            </div> */}

                <div className="repurchase-cart-in-list-flex">
                    <div className="repurchase-cart-in-list">
                        {activeStep === 1 && productsList && (
                            <UpgradeProducts
                                products={productsList?.upgradePackages}
                                setActiveStep={setActiveStep}
                                activeCurrencies={
                                    productsList?.activeCurrencies
                                }
                                cartData={cartData}
                                setCartData={setCartData}
                                setTotalAmount={setTotalAmount}
                                setTotalPv={setTotalPv}
                                totalAmount={totalAmount}
                                totalPv={totalPv}
                                userCountry={productsList?.userCountry}
                                conversionFactor={conversionFactor}
                                upfsvCriteria={productsList?.upfsvCriteria}
                                handleGstCalc={handleGstCalc}
                                handleShippingChargeCalc={
                                    handleShippingChargeCalc
                                }
                                courierType={courierType}
                                setCourierType={setCourierType}
                                setOptions1Active={setOptions1Active}
                                options1Active={options1Active}
                                errorsMessage={errorsMessage}
                                setErrorMessage={setErrorMessage}
                            />
                        )}
                        {activeStep === 2 && (
                            <UpgradeCheckOut
                                setActiveStepPr={setActiveStep}
                                cartData={cartData}
                                setCartData={setCartData}
                                setTotalAmount={setTotalAmount}
                                setTotalPv={setTotalPv}
                                totalAmount={totalAmount}
                                totalPv={totalPv}
                                userCountry={productsList?.userCountry}
                                userData={productsList?.userData}
                                conversionFactor={conversionFactor}
                                upfsvCriteria={productsList?.upfsvCriteria}
                                handleGstCalc={handleGstCalc}
                                gst={gst}
                                totalShippingCharge={totalShippingCharge}
                                handleShippingChargeCalc={
                                    handleShippingChargeCalc
                                }
                                courierType={courierType}
                                pgFeePerc={productsList?.pgFeePerc}
                                pgGSTPerc={productsList?.pgGSTPerc}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Upgrade;
