import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
import CommonInput from "../Common/CommonInputComponent";
import { toast } from "react-toastify";
const PreferredPassword = () => {
    const updateMutation = ApiHook.CallUpdatePreferredPlacement();
    const [selectedOption, setSelectedOption] = useState("left");
    const Profile = ApiHook.CallPreferredPlacement(setSelectedOption);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const isValid = () => {
        if (true) {
            return true;
        }
        return false;
    };
    const handleSubmit = () => {
        if (isValid()) {
            updateMutation.mutate(
                { preferredOption: selectedOption },
                {
                    onSuccess: (res) => {
                        if (res.status) {
                            toast.success(
                                "Preffered Placement Updated Successfully!!"
                            );
                        } else {
                            // toast.error("Given Old Password is invalid");
                        }
                    },
                }
            );
        } else {
            toast.error("Old password and Confirm password are not same ");
        }
    };
    return (
        <div className="container-bg-design common-div-container">
            <div className="container-bg-design-container-flex layot-max-width">
                <div className="container-bg-design-container">
                    <div className="common-div-header gta-b pb-0">
                        PREFERRED PLACEMENT
                    </div>
                    <div className="change-password-form-container-flex">
                        <div className="preferred-placement-form-container">
                            <div className="radio-container">
                                <div className="radio-container-input">
                                    <label>
                                        <input
                                            type="radio"
                                            value="left"
                                            className="left_right_radio"
                                            checked={selectedOption === "left"}
                                            onChange={handleOptionChange}
                                        />
                                        LEFT
                                    </label>
                                </div>
                                <div className="radio-container-input">
                                    <label>
                                        <input
                                            type="radio"
                                            value="right"
                                            className="left_right_radio"
                                            checked={selectedOption === "right"}
                                            onChange={handleOptionChange}
                                        />
                                        RIGHT
                                    </label>
                                </div>
                            </div>

                            <button onClick={handleSubmit}>SUBMIT</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreferredPassword;
