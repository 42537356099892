import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
import { exportToExcelNew } from "../../utils/tableExports";
const IbeMbcDeliveryReport = () => {
    const { t } = useTranslation();
    const headers = [
        { label: "MBC IBE #", filter: "mbc_ibe", filterable: false },
        { label: "MBC NAME", filter: "mbc_name", filterable: false },
        { label: "ORDER IBE #", filter: "ord_ibe", filterable: true },
        { label: "ORDER IBE NAME", filter: "ord_ibe_name", filterable: true },
        { label: "ORDER DATE", filter: "ord_date", filterable: true },
        { label: "ORDER #", filter: "ord_invoice", filterable: true },
        {
            label: "DELIVERY DATE TIME",
            filter: "delivery_date",
            filterable: true,
        },
        { label: "PRODUCT CODE", filter: "product_code", filterable: true },
        { label: "PRODUCT NAME", filter: "product_name", filterable: true },
        { label: "QTY", filter: "qty", filterable: true },
        { label: "CITY", filter: "city", filterable: true },
        { label: "STATE", filter: "state", filterable: true },
        { label: "COUNTRY", filter: "country", filterable: true },
        {
            label: "STATUS",
            filter: "status",
            filterable: false,
            render: (el) => {
                let color = "#3a8aa3";
                let backgroundColor = "#e5f0f2";

                return (
                    <div
                        style={{
                            backgroundColor,
                            color,
                            width: "110px",
                            padding: "3px 25px 3px 25px",
                            borderRadius: "10px",
                            fontWeight: "bold",
                        }}
                    >
                        {el}
                    </div>
                );
            },
        },
    ];

    const [apiCallStatus, setApiCallStatus] = useState(true);
    const [filters, setFilters] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const { data } = ApiHook.CallgetIbeMbcDeliveryReport({
        filters: Object.entries(filters)?.reduce((prev, curr) => {
            if (!curr[1]?.isEditting && curr[1]?.value !== "") {
                return { ...prev, [curr[0]]: curr[1]?.value };
            }
            return prev;
        }, {}),
        apiCallStatus,
        setApiCallStatus,
        setCurrentPage,
        setTotalPages,
        currentPage,
    });

    const startPage = 1;
    const itemsPerPage = 25;
    const toNextPage = () => {
        // setIsFetchable(true);
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            setApiCallStatus(true);
        }
    };

    const toLastPage = () => {
        setCurrentPage(totalPages);
        setApiCallStatus(true);
    };

    const toPreviousPage = () => {
        if (currentPage > startPage) {
            setCurrentPage(currentPage - 1);
            setApiCallStatus(true);
        }
    };

    const toFirstPage = () => {
        setCurrentPage(startPage);
        setApiCallStatus(true);
    };

    const slider = document.getElementsByClassName("report-table-container")[0];
    if (slider) {
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener("mousedown", (e) => {
            isDown = true;
            slider.classList.add("active");
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener("mouseleave", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mouseup", () => {
            isDown = false;
            slider.classList.remove("active");
        });
        slider.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }

    const exportFunc = () => {
        exportToExcelNew(data?.data, headers, "ibe-mbc-delivery-report");
    };
    return (
        <>
            <div
                className="report-Container"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <div className="report-header common-div-header">
                    IBE MBC DELIVERY REPORT
                </div>
                <div className="report-table-container-flex">
                    <div
                        className="report-table-container scrollbar-blue"
                        // style={{ display: "flex", flexDirection: "column" }}
                    >
                        <CommonTableComponent
                            headers={headers}
                            datas={data?.data ?? []}
                            filters={filters}
                            firstElementSticky={true}
                            searchOption={true}
                            setFilters={setFilters}
                            onSearch={() => {
                                setApiCallStatus(true);
                            }}
                        />
                    </div>
                </div>
                <div className="report-table-container-flex">
                    <div className="pagination-container">
                        {data?.data && data?.data?.length !== 0 && (
                            <TablePagination
                                startPage={startPage}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                itemsPerPage={itemsPerPage}
                                toNextPage={toNextPage}
                                toLastPage={toLastPage}
                                toPreviousPage={toPreviousPage}
                                toFirstPage={toFirstPage}
                                exportButton={true}
                                exportFunc={exportFunc}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IbeMbcDeliveryReport;
