import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useReactToPrint } from "react-to-print";
import { ApiHook } from "../../hooks/apiHook";
import logo_user from "../../assests/images/invoiceLogo.png";

import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { useSearchParams } from "react-router-dom";
import InvoiceModal from "./InvoiceModal";

const PurchaseInvoice = ({
    invoiceNoProps = null,
    typeProps = null,
    customStyle = false,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const isSuccess = searchParams.get("success");

    const invoiceNo = !invoiceNoProps ? params.id : invoiceNoProps;
    const type = !typeProps ? params.type : typeProps;

    const handleBack = () => {
        navigate("/repurchase-report");
    };

    return (
        <div style={{ height: "100dvh" }}>
            <div className="container-bg-design" style={{ height: "100vh" }}>
                {isSuccess && (
                    <div className="succuss-padding mb-2">
                        <div className="invoice-succuss">
                            <img
                                className="succuss_image"
                                src="/images/succuss-check.svg"
                                alt=""
                            />{" "}
                            <span className="invoice-succuss_msg">
                                Order Successfully Completed. And your order
                                Invoice No is{" "}
                                <span className="invoice_num">
                                    {" "}
                                    {invoiceNo}
                                </span>
                            </span>
                        </div>
                    </div>
                )}
                <InvoiceModal invoiceNo={invoiceNo} type={type} />
            </div>
        </div>
    );
};

export default PurchaseInvoice;
