import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

const SocialInvites = (props) => {
    const { t } = useTranslation();
    const socialShare = (link) => {
        window.open(`${link}`, "_blank", "noopener,noreferrer");
    };
    const facebookData =
        (props.data &&
            props.data.filter((item) => item.type === "social_facebook")) ||
        [];
    const instagramData =
        (props.data &&
            props.data.filter((item) => item.type === "social_instagram")) ||
        [];
    const twitterData =
        (props.data &&
            props.data.filter((item) => item.type === "social_twitter")) ||
        [];

    return (
        <div
            className="tab-pane fade active show"
            id="nav-banner"
            role="tabpanel"
            aria-labelledby="nav-social-tab"
        >
            {props.isLoading ? (
                <>
                    <h4 className="heading_style_1 mt-2">
                        <Skeleton />
                    </h4>
                    <div className="d-flex gap-2">
                        <Skeleton />
                    </div>
                </>
            ) : (
                <>
                    {facebookData.length > 0 && (
                        <>
                            <div className="promotional-social-invites-icon">
                                <img
                                    src={`images/facebook.svg`}
                                    alt={"facebook"}
                                />
                                <h4 className="heading_style_1 mt-2">
                                    Facebook
                                </h4>
                            </div>
                            {facebookData.map((item, index) => (
                                <>
                                    <div key={index} className="d-flex gap-2">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: item.content,
                                            }}
                                            className="form-control"
                                            disabled
                                        ></div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                                socialShare(item.targetUrl)
                                            }
                                        >
                                            {t("share")}
                                        </button>
                                    </div>
                                    <br />
                                </>
                            ))}
                        </>
                    )}
                    {instagramData.length > 0 && (
                        <>
                            <div className="promotional-social-invites-icon">
                                <img
                                    src={`images/instagram.svg`}
                                    alt={"facebook"}
                                />
                                <h4 className="heading_style_1 mt-2">
                                    Instagram
                                </h4>
                            </div>
                            {instagramData.map((item, index) => (
                                <>
                                    <div key={index} className="d-flex gap-2">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: item.content,
                                            }}
                                            className="form-control"
                                            disabled
                                        ></div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                                socialShare(item.targetUrl)
                                            }
                                        >
                                            {t("share")}
                                        </button>
                                    </div>
                                    <br />
                                </>
                            ))}
                        </>
                    )}
                    {twitterData.length > 0 && (
                        <>
                            <div className="promotional-social-invites-icon">
                                <img
                                    src={`images/twitter.svg`}
                                    alt={"twitter-X"}
                                />
                                <h4 className="heading_style_1 mt-2">
                                    Twitter
                                </h4>
                            </div>
                            {twitterData.map((item, index) => (
                                <>
                                    <div key={index} className="d-flex gap-2">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: item.content,
                                            }}
                                            className="form-control"
                                            disabled
                                        ></div>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() =>
                                                socialShare(item.targetUrl)
                                            }
                                        >
                                            {t("share")}
                                        </button>
                                    </div>
                                    <br />
                                </>
                            ))}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default SocialInvites;
