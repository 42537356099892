import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { exportToExcel } from "../../../utils/tableExports";

const TablePagination = ({
    startPage,
    currentPage,
    totalPages,
    itemsPerPage,
    handleItemsPerPageChange,
    toFirstPage,
    toPreviousPage,
    toNextPage,
    toLastPage,
    exportButton = false,
    exportFunc,
}) => {
    const { t } = useTranslation();
    const isLinkEnabled = currentPage !== startPage;
    const isLinkDisabled = currentPage !== totalPages;
    return (
        <div className="pagination_section_btm">
            <div className="row justify-content-between">
                {/* <div className="col-lg-4 col-md-12 page_select_sec">
          {t("items_per_page")}{" "}
          <select
            name="page"
            className="page_slect"
            onChange={handleItemsPerPageChange}
            value={itemsPerPage}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div> */}
                <div className="col-lg-4 col-md-6 table-export-button text-start">
                    {exportButton && (
                        <button onClick={exportFunc}>EXPORT TO EXCEL</button>
                    )}
                </div>
                <div className="col-lg-4 col-md-6 text-end order-lg-1">
                    <div className="mt-2 mt-sm-0">
                        <div className="w-sm_100">
                            <nav>
                                <ul className="pagination d-flex flex-wrap pagination-rounded-flat pagination-success m-0">
                                    {/* <li className="page-item">
                                        <Link
                                            className={`page-link ${
                                                isLinkEnabled ? "" : "disabled"
                                            }`}
                                            onClick={toFirstPage}
                                            data-abc="true"
                                        >
                                            <i className="fa fa-angle-double-left"></i>
                                        </Link>
                                    </li> */}
                                    <li className="page-item page_prev">
                                        <Link
                                            className={`page-link ${
                                                isLinkEnabled ? "" : "disabled"
                                            }`}
                                            onClick={toPreviousPage}
                                            data-abc="true"
                                        >
                                            <i className="fa fa-angle-left"></i>
                                        </Link>
                                    </li>
                                    {currentPage !== startPage && (
                                        <li className="page-item">
                                            <Link
                                                className="page-link  page-link-item"
                                                onClick={toFirstPage}
                                                data-abc="true"
                                            >
                                                {startPage}
                                            </Link>
                                        </li>
                                    )}
                                    {currentPage - startPage > 2 && (
                                        <li className="page-item">
                                            <Link
                                                className="page-link page-link-item"
                                                data-abc="true"
                                            >
                                                ...
                                            </Link>
                                        </li>
                                    )}
                                    {currentPage - startPage === 2 && (
                                        <li className="page-item">
                                            <Link
                                                className="page-link page-link-item"
                                                onClick={toPreviousPage}
                                                data-abc="true"
                                            >
                                                {currentPage - 1}
                                            </Link>
                                        </li>
                                    )}
                                    <li className="page-item page_active">
                                        <Link
                                            className="page-link page-link-item"
                                            data-abc="true"
                                        >
                                            {currentPage ?? 0}
                                        </Link>
                                    </li>
                                    {totalPages - currentPage > 2 && (
                                        <li className="page-item">
                                            <Link
                                                className="page-link page-link-item"
                                                data-abc="true"
                                            >
                                                ...
                                            </Link>
                                        </li>
                                    )}
                                    {totalPages - currentPage === 2 && (
                                        <li className="page-item">
                                            <Link
                                                className="page-link page-link-item"
                                                onClick={toNextPage}
                                                data-abc="true"
                                            >
                                                {totalPages - 1}
                                            </Link>
                                        </li>
                                    )}
                                    {currentPage !== totalPages && (
                                        <li className="page-item page_desable">
                                            <Link
                                                className="page-link page-link-item"
                                                onClick={toLastPage}
                                                data-abc="true"
                                            >
                                                {totalPages}
                                            </Link>
                                        </li>
                                    )}
                                    <li className="page-item page_next">
                                        <Link
                                            className={`page-link ${
                                                isLinkDisabled ? "" : "disabled"
                                            }`}
                                            onClick={toNextPage}
                                            data-abc="true"
                                        >
                                            <i className="fa fa-angle-right"></i>
                                        </Link>
                                    </li>
                                    {/* <li className="page-item">
                                        <Link
                                            className={`page-link ${
                                                isLinkDisabled ? "" : "disabled"
                                            }`}
                                            onClick={toLastPage}
                                            data-abc="true"
                                        >
                                            <i className="fa fa-angle-double-right"></i>
                                        </Link>
                                    </li> */}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-12">
                    <div className="mt-3 mt-md-0 text-center">
                        <span className="table_foot_text ">{`PAGE ${currentPage} OF ${totalPages} (${itemsPerPage} ITEMS)`}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TablePagination;
