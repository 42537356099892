import React, { useEffect, useState } from "react";
import ProductLists from "../../components/shopping/ProductLists";
import { ApiHook } from "../../hooks/apiHook";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CommonInput from "../../components/Common/CommonInputComponent";
import { event } from "jquery";
import { tr } from "date-fns/locale";

const ShoppingLayout = () => {
    const param = useParams();
    const { t } = useTranslation();
    const items = ApiHook.CallRepurchaseItems();
    const [repurchaseType, setRepurchaseType] = useState(param?.type ?? "");
    const [courierType, setCourierType] = useState(param?.courier ?? "");
    const [options1Active, setOptions1Active] = useState(false);

    const [optionsActive, setOptionsActive] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const backGround = "#e5f0f2";
    const [errorsMessage, setErrorMessage] = useState({
        repurchaseError: "",
        courierError: "",
    });

    return (
        <>
            <div
                className="container-bg-design-container-flex"
                style={{ height: "100vh", backgroundColor: "#fff" }}
            >
                <div className="container-bg-design repurchase-cart-in-container">
                    <div style={{ zIndex: "1", position: "relative" }}>
                        <div className="common-div-header gta-b">
                            IBE REPURCHASE SHOPPING CART
                        </div>
                        <>
                            <div className="repurchase-input-container-flex container-fluid mb-md-0 mb-5">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="repurchase-input-container px-3">
                                            <div
                                                className="repurchase-input-div  align-items-center"
                                                style={
                                                    optionsActive
                                                        ? {
                                                              "--pseudo-element-color":
                                                                  "#fff",
                                                              height: "94px",
                                                              display: "flex",
                                                              justifyContent:
                                                                  "space-between",
                                                          }
                                                        : {
                                                              "--pseudo-element-color":
                                                                  "#fff",
                                                              display: "flex",
                                                              justifyContent:
                                                                  "space-between",
                                                          }
                                                }
                                            >
                                                <div>
                                                    {!optionsActive &&
                                                        repurchaseType ===
                                                            "" && (
                                                            <div
                                                                className="repurchase-form-new-input"
                                                                style={
                                                                    optionsActive
                                                                        ? {
                                                                              height: "50%",
                                                                          }
                                                                        : {}
                                                                }
                                                                onClick={() => {
                                                                    setOptionsActive(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                Select
                                                                Repurchase Type
                                                            </div>
                                                        )}
                                                    {(optionsActive ||
                                                        repurchaseType ===
                                                            "PCV") && (
                                                        <div
                                                            className="repurchase-form-new-input"
                                                            style={
                                                                optionsActive
                                                                    ? {
                                                                          height: "50%",
                                                                      }
                                                                    : {}
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    !optionsActive
                                                                ) {
                                                                    setOptionsActive(
                                                                        true
                                                                    );
                                                                } else {
                                                                    setRepurchaseType(
                                                                        "PCV"
                                                                    );
                                                                    setOptionsActive(
                                                                        false
                                                                    );
                                                                    setErrorMessage(
                                                                        (
                                                                            state
                                                                        ) => ({
                                                                            ...state,
                                                                            repurchaseError:
                                                                                "",
                                                                        })
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            PCV
                                                        </div>
                                                    )}
                                                    {(optionsActive ||
                                                        repurchaseType ===
                                                            "QSV") && (
                                                        <div
                                                            className="repurchase-form-new-input"
                                                            style={
                                                                optionsActive
                                                                    ? {
                                                                          height: "50%",
                                                                      }
                                                                    : {}
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    !optionsActive
                                                                ) {
                                                                    setOptionsActive(
                                                                        true
                                                                    );
                                                                } else {
                                                                    setRepurchaseType(
                                                                        "QSV"
                                                                    );
                                                                    setOptionsActive(
                                                                        false
                                                                    );
                                                                    setErrorMessage(
                                                                        (
                                                                            state
                                                                        ) => ({
                                                                            ...state,
                                                                            repurchaseError:
                                                                                "",
                                                                        })
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            QSV
                                                        </div>
                                                    )}
                                                </div>{" "}
                                                <button
                                                    style={{
                                                        height: "50px",
                                                        width: "50px",
                                                        backgroundColor:
                                                            "transparent",
                                                        color: "#9B5731",
                                                    }}
                                                    onClick={() => {
                                                        setOptionsActive(
                                                            (state) => !state
                                                        );
                                                    }}
                                                >
                                                    {optionsActive ? (
                                                        <i className="fa-solid fa-caret-up"></i>
                                                    ) : (
                                                        <i className="fa-solid fa-caret-down"></i>
                                                    )}
                                                </button>
                                            </div>
                                            {errorsMessage?.repurchaseError !==
                                                "" && (
                                                <span className="error-message-validator">
                                                    {
                                                        errorsMessage?.repurchaseError
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="repurchase-input-container px-3">
                                            <div
                                                className="repurchase-input-div  align-items-center"
                                                style={
                                                    options1Active
                                                        ? {
                                                              "--pseudo-element-color":
                                                                  "#fff",
                                                              height: "94px",
                                                              display: "flex",
                                                              justifyContent:
                                                                  "space-between",
                                                          }
                                                        : {
                                                              "--pseudo-element-color":
                                                                  "#fff",
                                                              display: "flex",
                                                              justifyContent:
                                                                  "space-between",
                                                          }
                                                }
                                            >
                                                <div>
                                                    {!options1Active &&
                                                        courierType === "" && (
                                                            <div
                                                                className="repurchase-form-new-input"
                                                                style={
                                                                    options1Active
                                                                        ? {
                                                                              height: "50%",
                                                                          }
                                                                        : {}
                                                                }
                                                                onClick={() => {
                                                                    setOptions1Active(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                Select Delivery
                                                                Mode
                                                            </div>
                                                        )}
                                                    {(options1Active ||
                                                        courierType ===
                                                            "Courier") && (
                                                        <div
                                                            className="repurchase-form-new-input"
                                                            style={
                                                                options1Active
                                                                    ? {
                                                                          height: "50%",
                                                                      }
                                                                    : {}
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    !options1Active
                                                                ) {
                                                                    setOptions1Active(
                                                                        true
                                                                    );
                                                                } else {
                                                                    setCourierType(
                                                                        "Courier"
                                                                    );
                                                                    setOptions1Active(
                                                                        false
                                                                    );
                                                                    setErrorMessage(
                                                                        (
                                                                            state
                                                                        ) => ({
                                                                            ...state,
                                                                            courierError:
                                                                                "",
                                                                        })
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            Courier
                                                        </div>
                                                    )}
                                                    {(options1Active ||
                                                        courierType ===
                                                            "Selfpickup") && (
                                                        <div
                                                            className="repurchase-form-new-input"
                                                            style={
                                                                options1Active
                                                                    ? {
                                                                          height: "50%",
                                                                      }
                                                                    : {}
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    !options1Active
                                                                ) {
                                                                    setOptions1Active(
                                                                        true
                                                                    );
                                                                } else {
                                                                    setCourierType(
                                                                        "Selfpickup"
                                                                    );
                                                                    setOptions1Active(
                                                                        false
                                                                    );
                                                                    setErrorMessage(
                                                                        (
                                                                            state
                                                                        ) => ({
                                                                            ...state,
                                                                            courierError:
                                                                                "",
                                                                        })
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            Self pickup
                                                        </div>
                                                    )}
                                                </div>

                                                <button
                                                    style={{
                                                        height: "50px",
                                                        width: "50px",
                                                        backgroundColor:
                                                            "transparent",
                                                        color: "#9B5731",
                                                    }}
                                                    onClick={() => {
                                                        setOptions1Active(
                                                            (state) => !state
                                                        );
                                                    }}
                                                >
                                                    {options1Active ? (
                                                        <i className="fa-solid fa-caret-up"></i>
                                                    ) : (
                                                        <i className="fa-solid fa-caret-down"></i>
                                                    )}
                                                </button>
                                            </div>
                                            {errorsMessage?.courierError !==
                                                "" && (
                                                <span className="error-message-validator">
                                                    {
                                                        errorsMessage?.courierError
                                                    }
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="repurchase-cart-in-list-flex pb-lg-0 pb-5">
                                <div className="repurchase-cart-in-list d-flex justify-content-center">
                                    {items?.data && (
                                        <ProductLists
                                            products={items?.data?.productList}
                                            repurchaseType={repurchaseType}
                                            courierType={courierType}
                                            conversionFactor={
                                                items?.data?.conversionFactor
                                            }
                                            setErrorMessage={setErrorMessage}
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShoppingLayout;
