export const PersonalDetailsFormFields = [
    { name: "ibeNo", label: "IBE #", type: "text", placeholder: "IBE" },
    {
        name: "sponsorName",
        label: "SPONSOR NAME",
        type: "text",
        placeholder: "Sponser Name",
    },
    {
        name: "placement",
        label: "PLACEMENT L/R",
        type: "select",
        options: ["Left", "Right"],
    },
    {
        name: "fatherName",
        label: "FATHER NAME/SURNAME",
        type: "text",
        placeholder: "Father Name",
    },
    {
        name: "firstName",
        label: "FIRST NAME",
        type: "text",
        placeholder: "John John",
    },
    {
        name: "secondName",
        label: "LAST NAME",
        type: "text",
        placeholder: "John John",
    },
    {
        name: "gender",
        label: "GENDER",
        type: "select",
        options: ["Male", "Female", "Other"],
    },
    { name: "dob", label: "DATE OF BIRTH", type: "date" },
    { name: "age", label: "AGE", type: "number", placeholder: "Age" },
    {
        name: "maritalStatus",
        label: "MARITAL STATUS",
        type: "select",
        options: ["Single", "Married", "Divorced", "Widowed"],
    },
    {
        name: "whatsappNumber",
        label: "WHATS APP NUMBER",
        type: "mobile",
        placeholder: "0000000000",
        options: ["1", "91", "880"],
    },
    {
        name: "mobileNumber",
        label: "MOBILE NUMBER",
        type: "mobile",
        options: ["1", "91", "880"],
        placeholder: "0000000000",
    },
    {
        name: "email",
        label: "EMAIL",
        type: "email",
        placeholder: "test@email.com",
    },
    { name: "panNo", label: "PAN NUMBER", type: "text", placeholder: "000000" },
    {
        name: "education",
        label: "EDUCATION",
        type: "select",
        options: [
            "High School Diploma / GED",
            "Associate Degree",
            "Bachelor's Degree",
            "Master's Degree",
            "Doctoral Degree",
            "Other",
        ],
    },
    {
        name: "occupation",
        label: "OCCUPATION",
        type: "select",
        options: ["Salaried", "Self-employed", "Other"],
    },
    {
        name: "address1",
        label: "ADDRESS 1",
        type: "text",
        placeholder: "Address Line 1",
    },
    {
        name: "address2",
        label: "ADDRESS 2",
        type: "text",
        placeholder: "Address Line 2",
    },
    {
        name: "country",
        label: "COUNTRY",
        type: "select",
        options: ["India", "USA", "Other"],
    },
    { name: "city", label: "CITY", type: "text", placeholder: "City" },
    {
        name: "state",
        label: "STATE",
        type: "select",
        options: ["TamilNadu", "Kerala", "Other"],
    },
    { name: "pincode", label: "PIN CODE", type: "text", placeholder: "000000" },
];

export const BankDetailsFormFields = [
    {
        name: "holderName",
        label: "BANK ACCOUNT NAME",
        type: "text",
        placeholder: "John",
    },
    {
        name: "bankName",
        label: "BANK NAME",
        type: "text",
        placeholder: "ICICI Bank",
    },
    {
        name: "accountNo",
        label: "BANK ACCOUNT NUMBER",
        type: "text",
        placeholder: "77777777777777",
    },
    {
        name: "ifsc",
        label: "IFSC/ROUTING/BANK#",
        type: "text",
        placeholder: "ICIC0002708",
    },
];
