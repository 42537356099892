export const addNewMessageToInfiniteData = (queryClient, data, id) => {
  queryClient.setQueryData(["ticket-comment", id], (oldData) => {
    if (!oldData) return oldData;
    const updatedPages = oldData.pages.map((page, index) => {
      if (page?.currentPage === 1) {
        return {
          ...page,
          data: [data, ...page.data],
        };
      }
      return page;
    });

    return {
      ...oldData,
      pages: updatedPages,
    };
  });
};
