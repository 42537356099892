import API from "../../api/api";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const StockpointService = {
    callIbeMbcDeliveryReport: async (filters, page) => {
        const response = await callApi(
            `ibe-mbc-delivery-report?filters=${filters}&page=${page}`
        );
        return response;
    },
    callIbeMbcCommissionReport: async (filters, page) => {
        const response = await callApi(
            `ibe-mbc-commission-report?filters=${filters}&page=${page}`
        );
        return response;
    },
    callIbeMbcInventoryReport: async (filters) => {
        const response = await callApi(
            `ibe-mbc-inventory-report?filters=${filters}`
        );
        return response;
    },
    getIbeMbcSearchOrd: async (orderInvoice) => {
        const response = await callApi(
            `ibe-mbc-delivery?orderInvoice=${orderInvoice}`
        );
        return response;
    },
    sendIbeMbcDeliveryOtp: async (body) => {
        const response = await API.post(`send-delivery-otp`, body);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response;
        }
    },
    deliverOrder: async (body) => {
        const response = await API.post(`deliver-order`, body);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response;
        }
    },
};
