import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { toast } from "react-toastify";
import CommonInput from "../../components/Common/CommonInputComponent";
import {
    AddOrUpdateNominee,
    GetNomineeDetails,
} from "../../services/additonalModules/additionalFeature";
import CustomSelectBox from "../../components/Common/CustomSelectBox";

const Nominee = () => {
    const { data } = GetNomineeDetails();

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [relationship, setRelationship] = useState({});
    const [selectedDate, setSelectedDate] = useState("");
    const [phoneCode, setPhoneCode] = useState("91");
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);

    const result = AddOrUpdateNominee();

    const OnNameChange = (e) => {
        setName(e.target.value);
    };

    const onPhoneNumberChange = (e) => {
        if (isNaN(e.target.value)) return;
        if (e.target.value.trim().length >= 11) return;
        setPhone(e.target.value.trim());
    };

    const handleSelectRelation = (selectedRelation) => {
        setRelationship(selectedRelation);
    };

    const relationships = [
        { label: "Parent", value: "Parent" },
        { label: "Sibling", value: "Sibling" },
        { label: "Child", value: "Child" },
        { label: "Spouce", value: "Spouce" },
        { label: "Friend", value: "Friend" },
        { label: "Other", value: "Other" },
    ];

    const onDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const handleOpenChange = (isOpen) => {
        setIsOpenCalendar(isOpen);
    };

    const handlePhoneCodeChange = (e) => {
        if (isNaN(e.target.value)) return;
        if (e.target.value.trim().length > 3) return;
        setPhoneCode(e.target.value.trim());
    };

    const handleUpdatePhoneCode = (type) => {
        if (type === "increment") {
            setPhoneCode((prev) => {
                if (prev === 999) return prev;
                return prev + 1;
            });
        } else if ("decrement") {
            setPhoneCode((prev) => {
                if (prev === 0) return prev;
                return prev - 1;
            });
        }
    };

    const handleSubmit = () => {
        if (
            !name.trim() ||
            !relationship.label ||
            !selectedDate ||
            !phone ||
            !phoneCode
        ) {
            toast.error("Please fill all fields!");
            return;
        }

        let data = {
            name: name,
            relation: relationship.label,
            dob: selectedDate.format("YYYY-MM-DD"),
            mobile: phoneCode + "" + -+(phone + ""),
        };

        result.mutate(data, {
            onSuccess: (res) => {
                if (res.status) {
                    toast.success("Nominee updated successfully!");
                } else {
                    toast.error("Error updating nominee!");
                }
            },
        });
    };

    useEffect(() => {
        setPhone(data?.mobile?.split("-")[1] || "");
        setPhoneCode(data?.mobile?.split("-")[0] || 91);
        setName(data?.name || "");
        setSelectedDate(dayjs(data?.dob));
        setRelationship(data?.relation ? { label: data?.relation } : {});
    }, [data]);

    return (
        <div className="nominee-parent container-bg-design common-div-container">
            <div className="nominee-content">
                <h1 style={{ margin: "6vh 0 8vh 0" }} className="bebas-neue-regular">NOMINEE DETAILS</h1>

                <CommonInput
                    label={"NOMINEE NAME"}
                    placeholder={"Nominee name"}
                    onChange={OnNameChange}
                    value={name}
                    backgroundColor="#e5f0f2"
                />

                <label className="custom-label">NOMINEE RELATIONSHIP</label>
                <CustomSelectBox
                    value={relationship}
                    placeHolder={"Select Relationship"}
                    options={relationships || []}
                    onSelect={handleSelectRelation}
                />

                <label>NOMINEE DATE OF BIRTH</label>
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "10px 0",
                        paddingRight: "10px",
                    }}
                    className="square-border-cut"
                >
                    <div style={{ paddingLeft: "15px" }}>
                        <span>
                            {selectedDate
                                ? selectedDate.format("YYYY-MM-DD")
                                : "Select date of birth"}
                        </span>
                    </div>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => handleOpenChange(true)}
                    >
                        <i
                            className="fa-regular fa-calendar fa-xl"
                            style={{ color: "#9b5731" }}
                        ></i>
                        <div
                            style={{ position: "relative" }}
                            className="nominee-datepicker"
                        >
                            <DatePicker
                                suffixIcon={null}
                                style={{
                                    position: "absolute",
                                    opacity: 0.1,
                                    width: 0,
                                    height: 0,
                                    right: "80%",
                                }}
                                open={isOpenCalendar}
                                onOpenChange={handleOpenChange}
                                className="test-date-picker"
                                onChange={onDateChange}
                            />
                        </div>
                    </div>
                </div>

        <label>NOMINEE MOBILE NUMBER</label>
        <div style={{ position: "relative", width: "100%", paddingLeft: "10px",  marginBottom: "25px" }} className="square-border-cut">
          <div style={{ display: "flex", alignItems: "center", width: "8%" }}>
            <span style={{ fontWeight: "bold" }}>+</span>
            <input value={phoneCode} onChange={handlePhoneCodeChange} className="custom-placeholder no-active-outline" style={{ height: "60px", width: "80%", fontWeight: "bold", backgroundColor: "#e5f0f2" }} type="text" placeholder="91" />
          </div>

                    <div
                        style={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "5px",
                            gap: "5px",
                        }}
                    >
                        <i
                            onClick={() => handleUpdatePhoneCode("increment")}
                            className="fa-solid fa-caret-up fa-sm"
                            style={{ color: "#9b5731", cursor: "pointer" }}
                        ></i>
                        <i
                            onClick={() => handleUpdatePhoneCode("decrement")}
                            className="fa-solid fa-caret-down fa-sm"
                            style={{ color: "#9b5731", cursor: "pointer" }}
                        ></i>
                    </div>

                    <input
                        value={phone}
                        onChange={onPhoneNumberChange}
                        className="custom-placeholder no-active-outline"
                        style={{
                            flexGrow: 1,
                            marginRight: "10px",
                            marginLeft: "5px",
                            height: "60px",
                            fontWeight: "bold",
                            backgroundColor: "#e5f0f2"
                        }}
                        placeholder="0000000000"
                        type="text"
                    />
                </div>

                <button className="button-maxwidth" onClick={handleSubmit}>
                    <span style={{ fontWeight: 700, color: "white" }}>
                        SUBMIT
                    </span>
                </button>
            </div>
        </div>
    );
};

export default Nominee;
