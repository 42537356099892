import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

import API from "../../api/api";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const createFiltersData = (filters) => {
    return Object.entries(filters)?.reduce((prev, curr) => {
        console.log(curr);
        if (!curr[1]?.isEditting && curr[1]?.value !== "") {
            return { ...prev, [curr[0]]: curr[1]?.value };
        }
        return prev;
    }, {});
};

export const GetNomineeDetails = () =>
    useQuery({
        queryKey: ["nominee"],
        queryFn: () => callApi(`/nominee`),
    });

export const AddOrUpdateNominee = () =>
    useMutation({
        mutationFn: (data) => API.post("nominee", data),
    });

export const GetTrainingScoreReport = (setTotalPages, page, itemsPerPage) =>
    useQuery({
        queryKey: ["training-score", page, itemsPerPage],
        queryFn: () =>
            callApi(
                `/ibe-training-score-report?page=${page}&perPage=${itemsPerPage}`
            ),
        onSuccess: (res) => {
            setTotalPages(res.totalPages || 1);
        },
    });

export const GetIbeCommissionReport = (
    setTotalPages,
    page,
    itemsPerPage,
    apiCallStatus,
    setApiCallStatus,
    filters
) =>
    useQuery({
        queryKey: ["commission-report", page, itemsPerPage],
        queryFn: () => {
            const filterObject = createFiltersData(filters);

            const filter =
                Object.keys(filterObject).length !== 0
                    ? JSON.stringify(filterObject)
                    : "";

            return callApi(
                `/ibe-commission-report?page=${page}&perPage=${itemsPerPage}&filter=${filter}`
            );
        },
        enabled: !!apiCallStatus,
        onSuccess: (res) => {
            setApiCallStatus(false);
            setTotalPages(res.totalPages || 1);
        },
    });

export const GetDirectEnrolmentReport = (
    setTotalPages,
    page,
    itemsPerPage,
    apiCallStatus,
    setApiCallStatus,
    filters
) =>
    useQuery({
        queryKey: ["commission-report", page, itemsPerPage],
        queryFn: () => {
            const filterObject = createFiltersData(filters);

            const filter =
                Object.keys(filterObject).length !== 0
                    ? JSON.stringify(filterObject)
                    : "";

            return callApi(
                `/ibe-direct-enrolment-report?page=${page}&perPage=${itemsPerPage}&filter=${filter}`
            );
        },
        // enabled: !!apiCallStatus,
        onSuccess: (res) => {
            setApiCallStatus(false);
            setTotalPages(res.totalPages || 1);
        },
    });

export const GetIbeRankReport = (
    setTotalPages,
    page,
    itemsPerPage,
    apiCallStatus,
    setApiCallStatus,
    filters
) =>
    useQuery({
        queryKey: ["commission-report", page, itemsPerPage],
        queryFn: () => {
            const filter =
                Object.keys(filters).length !== 0
                    ? JSON.stringify(filters)
                    : "";

            return callApi(
                `/ibe-rank-report?page=${page}&perPage=${itemsPerPage}&filter=${filter}`
            );
        },
        enabled: !!apiCallStatus,
        onSuccess: (res) => {
            setApiCallStatus(false);
            setTotalPages(res.totalPages || 1);
        },
    });

export const GetGenealogyTreeReport = ({
    filters,
    apiCallStatus,
    setApiCallStatus,
    setCurrentPage,
    setTotalPages,
    currentPage,
    itemsPerPage,
    isAll = 0,
    exportFunc = () => {},
}) => {
    const response = useQuery({
        queryKey: [
            "get-genealogy-tree-report",
            filters,
            currentPage,
            itemsPerPage,
        ],
        queryFn: () =>
            callApi(
                `/get-genealogy-tree-report?filters=${JSON.stringify(
                    filters
                )}&limit=${itemsPerPage}&page=${currentPage}&isAll=${isAll}`
            ),
        enabled: !!apiCallStatus,
        onSuccess: (res) => {
            setApiCallStatus(false);
            setCurrentPage(res?.currentPage);
            setTotalPages(res?.totalPages);
            if (isAll) {
                exportFunc(res?.data);
            }
        },
    });
    return response;
};

export const GetIbeTeamVolumeReport = ({
    filters,
    apiCallStatus,
    setApiCallStatus,
    setCurrentPage,
    setTotalPages,
    currentPage,
    itemsPerPage,
    isAll = 0,
    exportFunc = () => {},
}) => {
    const response = useQuery({
        queryKey: [
            "get-ibe-team-volume-report",
            filters,
            currentPage,
            itemsPerPage,
        ],
        queryFn: () =>
            callApi(
                `/get-ibe-team-volume-report?filters=${JSON.stringify(
                    filters
                )}&limit=${itemsPerPage}&page=${currentPage}&isAll=${isAll}`
            ),
        enabled: !!apiCallStatus,
        onSuccess: (res) => {
            setApiCallStatus(false);
            setCurrentPage(res?.currentPage);
            setTotalPages(res?.totalPages);
            if (isAll) {
                exportFunc(res?.data);
            }
        },
    });
    return response;
};

export const GetTicketComments = (ticketId) => {
    return useInfiniteQuery({
        queryKey: ["ticket-comment", ticketId],
        queryFn: async ({ pageParam = 1 }) => {
            let response = await callApi(
                `/comment/${ticketId}?page=${pageParam}`
            );

            return {
                data: response.comment,
                currentPage: response?.currentPage,
                nextPage: response.nextPage,
                prevPage: response.prevPage,
            };
        },
        enabled: !!ticketId,
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
            return lastPage.nextPage;
        },
    });
};

export const GetTicket = (ticketId) => {
    return useQuery({
        queryKey: ["ticket", ticketId],
        queryFn: () => callApi("/ticket/" + ticketId),
        enabled: !!ticketId,
    });
};

export const AddNewComment = () => {
    const response = useMutation({
        mutationFn: async ({ message, ticketId }) =>
            API.post("/comment/" + ticketId, { message }),
    });

    return response;
};

export const GetIbeCommissionStatusReport = ({
    setTotalPages,
    itemsPerPage,
    apiCallStatus,
    setApiCallStatus,
    filters,
    currentPage,
}) =>
    useQuery({
        queryKey: ["commission-status-report", currentPage, itemsPerPage],
        queryFn: () => {
            const filter =
                Object.keys(filters).length !== 0
                    ? JSON.stringify(filters)
                    : null;

            return callApi(
                `/ibe-commission-status-report?page=${currentPage}&perPage=${itemsPerPage}&filter=${filter}`
            );
        },
        enabled: !!apiCallStatus,
        onSuccess: (res) => {
            setApiCallStatus(false);
            setTotalPages(res.totalPages || 1);
        },
    });
