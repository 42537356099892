import API from "../../api/api";

export const eventService = {
    getEvents: async () => {
    const response = API.get(`get-events`);
    return response;
  },
  
  eventInvoice: async (id) => {
    const response = API.get(`get-event-invoice?id=${id}`);
    return response;
  },
  eventPurchase: async (data) => {
    const response = API.post(`event-purchase`, JSON.stringify(data));
    return response;
  },
  
};
