import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
import { exportToExcelNew } from "../../utils/tableExports";
import CommonInput from "../../components/Common/CommonInputComponent";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Offcanvas } from "react-bootstrap";
import PurchaseInvoice from "../../components/shopping/PurchaseInvoice";
import InvoiceModal from "../../components/shopping/InvoiceModal";
const IbeMbcOrderInvoice = ({ show, setShow, invoice, purchaseType }) => {
  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      {/* <Offcanvas
                show={show}
                onHide={() => {
                    handleClose();
                }}
                placement="center"
                id="orderInvoice"
                className="centered-offcanvas"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{"ORDER INVOICE"}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body> */}
      {show && (
        <>
        <div>
          <InvoiceModal
            invoiceNo={invoice}
            type={purchaseType}
            handleClose={handleClose}
            closeButton={true}
            // customStyle={true}
          />
        </div>
        </>
      )}
      {/* </Offcanvas.Body> */}
      {/* // </Offcanvas> */}
    </>
  );
};

export default IbeMbcOrderInvoice;
