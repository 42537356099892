import {
    CallGetAgentWalletRequestForm,
    CallGetAgentWalletRequestHistory,
    CallGetAgentWalletTransferHistory,
} from "../../services/agent/agent";

export const AgentWalletRequestHistory = async (page) => {
    try {
        const response = await CallGetAgentWalletRequestHistory(page);
        return response;
    } catch (error) {
        console.log(error.message);
    }
};

export const AgentWalletRequestForm = async (page) => {
    try {
        const response = await CallGetAgentWalletRequestForm(page);
        return response;
    } catch (error) {
        console.log(error.message);
    }
};

export const AgentWalletTransferHistory = async (page) => {
    try {
        const response = await CallGetAgentWalletTransferHistory(page);
        return response;
    } catch (error) {
        console.log(error.message);
    }
};
