import React, { useState } from "react";
import { PhoneInput } from "react-international-phone";
import { isValidPhoneNumber } from "libphonenumber-js";
import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import CommonInput from "../../components/Common/CommonInputComponent";

const CheckoutPayment = ({
    setActiveStep,
    registerFields,
    totalAmount,
    conversionFactor,
    handleSubmit,
    userData,
    transPass,
    setTransPass,
    gst,
    totalShippingCharge,
    upgradeSubscriptionMutation,
    pgGSTPerc,
    pgFeePerc,
}) => {
    const { t } = useTranslation();
    const backgroundColor = "#fff";
    const [transPassCheck, setTransPassCheck] = useState(false);
    const [transPassResposne, setTransPassResposne] = useState({
        success: null,
        error: null,
    });
    const [submitButtonActive, setSubmitButtonActive] = useState(true);

    const transPassCheckData = ApiHook.CallTransPasswordCheck(
        transPass,
        transPassCheck,
        setTransPassCheck,
        setSubmitButtonActive,
        (Number(totalAmount) + Number(totalShippingCharge)) *
            (1 + pgFeePerc * (1 + pgGSTPerc)),
        transPassResposne,
        setTransPassResposne,
        "e-wallet"
    );
    return (
        <>
            <div className="checkout_contant_area_section">
                <div className="checkout_contant_cart_sec">
                    <div id="animation">
                        <div className="">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <div className="register-verification-container">
                                    <CommonInput
                                        type="text"
                                        label="Total Amount"
                                        backgroundColor={backgroundColor}
                                        value={`${
                                            conversionFactor?.symbolLeft
                                        } ${CustomCurrencyConverter(
                                            (Number(totalAmount) +
                                                Number(totalShippingCharge)) *
                                                (1 +
                                                    pgFeePerc *
                                                        (1 + pgGSTPerc)),
                                            conversionFactor?.value
                                        )}`}
                                        defaultValue=""
                                        disabled={true}
                                    />
                                    <CommonInput
                                        type="text"
                                        label="IBE #"
                                        backgroundColor={backgroundColor}
                                        value={userData?.username}
                                        defaultValue=""
                                        disabled={true}
                                    />
                                    <CommonInput
                                        type="password"
                                        label="Transaction Password"
                                        placeholder={
                                            "Your Transaction password"
                                        }
                                        backgroundColor={backgroundColor}
                                        value={transPass}
                                        onChange={(e) => {
                                            setTransPass(e.target.value);
                                        }}
                                        defaultValue=""
                                    />
                                    {transPassResposne?.error && (
                                        <span className="error-message-validator">
                                            {t(transPassResposne?.error)}
                                        </span>
                                    )}

                                    <button
                                        className="common-button"
                                        onClick={(e) => {
                                            setTransPassCheck(true);
                                            // handleFormValueChange(2, "paymentType", {
                                            //     shouldValidate: true,
                                            //     shouldDirty: true,
                                            // });
                                        }}
                                    >
                                        CHECK
                                    </button>
                                    {transPassResposne?.success && (
                                        <button
                                            className="common-button"
                                            onClick={(e) => {
                                                handleSubmit();
                                            }}
                                            disabled={
                                                upgradeSubscriptionMutation?.status ===
                                                "loading"
                                            }
                                        >
                                            {upgradeSubscriptionMutation?.status ===
                                            "loading"
                                                ? "SUBMITTING.."
                                                : "SUBMIT"}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CheckoutPayment;
