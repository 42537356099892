import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import CheckoutPackage from "../../components/shopping/CheckoutPackage";
import CheckoutAddress from "../../components/shopping/CheckoutAddress";
import CheckoutOrderSummary from "../../components/shopping/CheckoutOrderSummary";
import CheckoutPayment from "../../components/shopping/CheckoutPayment";
import anime from "animejs/lib/anime.es.js";
import { ApiHook } from "../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";

const CheckoutLayout = () => {
    const params = useParams();

    const [activeStep, setActiveStep] = useState(1);
    const queryClient = useQueryClient();

    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
        register,
        setValue,
        setError,
        trigger,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            product: [],
        },
    });
    const formValues = watch();
    // ----------------------------------- API -------------------------------------
    const items = ApiHook.CallCartItems();
    // const removeCartMutation = ApiHook.CallRemoveCartItem();
    const repurchaseMutation = ApiHook.CallPlaceRepurchaseOrder();
    const [isLoading, setIsLoading] = useState(false);

    const addCartMutation = ApiHook.CallAddToCart();
    const decrementCartMutation = ApiHook.CallDecrementCartItem();
    const removeCartMutation = ApiHook.CallRemoveCartItem();

    // if (items?.length === 0) {
    //     navigate("/shopping");
    // }

    const handleQuantityChange = (event, packageId) => {
        if (event.target.id === "plus") {
            addCartMutation.mutate(
                { packageId: packageId },
                {
                    onSuccess: (res) => {
                        if (res.status) {
                            queryClient.invalidateQueries({
                                queryKey: ["cart-items"],
                            });
                        }
                    },
                }
            );
        } else if (event.target.id === "minus") {
            decrementCartMutation.mutate(
                { packageId: packageId },
                {
                    onSuccess: (res) => {
                        if (res.status) {
                            queryClient.invalidateQueries({
                                queryKey: ["cart-items"],
                            });
                        } else {
                            queryClient.invalidateQueries({
                                queryKey: ["cart-items"],
                            });
                        }
                    },
                }
            );
        }
    };

    const handleRemoveCartItem = (packageId) => {
        removeCartMutation.mutate(
            { packageId: packageId },
            {
                onSuccess: (res) => {
                    if (res.status) {
                        queryClient.invalidateQueries({
                            queryKey: ["cart-items"],
                        });
                        if (items?.data?.length === 0) {
                            navigate("/shopping");
                        }
                    }
                },
            }
        );
    };
    useEffect(() => {
        const list = items.data?.cartList?.map((product) => ({
            name: product.name,
            quantity: product.quantity,
            totalAmount: product.price * product.quantity,
        }));
        setValue("product", list);
        setValue("totalAmount", totalAmount);
        setShippingCharge(
            params?.courier === "Courier" ? items?.data?.courierCharge : 0
        );
        setpgFeePerc(items?.data?.paymentGatewayFeePerc);
        setpgGSTPerc(items?.data?.paymentGatewayGstPerc);
    }, [items.data?.cartList]);

    const handleFormValueChange = (value, type, options = {}) => {
        setValue(`${type}`, value, options);
        setError(`${type}`, { message: "" });
    };

    const initiatePayment = async ({ payuDatas: datas, payuBaseUrl: url }) => {
        try {
            const form = document.createElement("form");
            form.action = url;
            form.method = "POST";

            for (let [key, value] of Object.entries(datas)) {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = value;
                form.appendChild(input);
            }

            document.body.appendChild(form);
            form.submit();
        } catch (error) {
            console.error("Error initiating payment:", error);
        }
    };
    const handleNext = async () => {
        setIsLoading(true);
        let isValid = await trigger();
        if (isValid) {
            formValues.repurchaseType = params?.type ?? "PCV";
            formValues.courierType = params?.courier ?? "Selfpickup";

            if (items.data?.userCountry === "India") {
                formValues.paymentType = 9;
                repurchaseMutation.mutate(
                    {
                        ...formValues,
                        repurchaseType: params?.type ?? "PCV",
                        courierType: params?.courier ?? "Selfpickup",
                    },
                    {
                        onSuccess: async (res) => {
                            if (res?.data.status) {
                                if (res?.data?.data?.payuBaseUrl) {
                                    await initiatePayment(res?.data?.data);
                                } else {
                                    // toast.success(t(res?.data?.data?.));
                                    navigate(
                                        `/invoice/repurchase/${res?.data?.data?.invoiceNo}`
                                    );
                                }
                            } else {
                                toast.error(res?.data?.description);
                            }
                        },
                    }
                );
            } else {
                formValues.paymentType = 2;
                const nextStep = activeStep + 1;
                animateStepTransition(nextStep);
                setActiveStep(nextStep);
                setIsLoading(false);
            }
        }
    };

    const handleSubmit = () => {
        repurchaseMutation.mutate(
            {
                ...formValues,
                repurchaseType: params?.type ?? "PCV",
                courierType: params?.courier ?? "Selfpickup",
            },
            {
                onSuccess: (res) => {
                    if (res?.data.status) {
                        // toast.success(t(res?.data?.data?.message));
                        // navigate("/shopping");
                        navigate(
                            `/invoice/repurchase/${res?.data?.data?.invoiceNo}`
                        );
                    } else {
                        toast.error(res?.data?.description);
                    }
                },
            }
        );
    };
    const handleBack = () => {
        const prevStep = activeStep - 1;
        animateStepTransition(prevStep);
        setActiveStep(prevStep);
    };

    const animateStepTransition = () => {
        const containerElement = document.querySelector("#animation");
        // Set initial position of the next step
        anime.set(containerElement, { opacity: 0 });
        // Animate the next step in
        anime({
            targets: containerElement,
            opacity: [0, 1],
            duration: 1100,
            easing: "easeInOutQuad",
        });
    };

    // const handleClearAll = () => {
    //     removeCartMutation.mutate(
    //         { packageId: "all" },
    //         {
    //             onSuccess: () => {
    //                 navigate("/shopping");
    //             },
    //         }
    //     );
    // };

    const totalAmount = items.data?.cartList?.reduce(
        (total, product) => total + product.price * product.quantity,
        // total + product.price * (items.data?.gst + 1) * product.quantity,
        0
    );
    const [shippingCharge, setShippingCharge] = useState(
        params?.courier === "Courier" ? items?.data?.courierCharge : 0
    );
    const [pgFeePerc, setpgFeePerc] = useState(
        items?.data?.paymentGatewayFeePerc
    );
    const [pgGSTPerc, setpgGSTPerc] = useState(
        items?.data?.paymentGatewayGstPerc
    );

    return (
        <div
            className="container-bg-design-container-flex"
            style={
                activeStep === 4
                    ? { height: "100vh", backgroundColor: "#fff" }
                    : { height: "100vh" }
            }
        >
            <div className="container-bg-design">
                <div className="container">
                    <div className="common-div-header gta-b">CHECKOUT CART</div>
                    {/* big screen */}
                    <div className="checkout_contant_area_section d-md-block d-none">
                        <div className="checkout_contant_cart_sec">
                            <div id="animation">
                                {activeStep === 1 && items.data && (
                                    <CheckoutPackage
                                        items={items.data?.cartList}
                                        totalAmount={totalAmount}
                                        handleNext={handleNext}
                                        conversionFactor={
                                            items.data?.conversionFactor
                                        }
                                        gst={items?.data?.gst}
                                        shippingCharge={shippingCharge}
                                        pgFeePerc={pgFeePerc}
                                        pgGSTPerc={pgGSTPerc}
                                        isPaymentGatewayEnabled={
                                            items.data?.userCountry === "India"
                                        }
                                        isLoading={isLoading}
                                        handleRemoveCartItem={
                                            handleRemoveCartItem
                                        }
                                        handleQuantityChange={
                                            handleQuantityChange
                                        }
                                        addCartMutation={addCartMutation}
                                        decrementCartMutation={
                                            decrementCartMutation
                                        }
                                        removeCartMutation={removeCartMutation}
                                    />
                                )}
                                {/* {activeStep === 2 && (
                            <CheckoutAddress
                                totalAmount={totalAmount}
                                handleNext={handleNext}
                                show={show}
                                setShow={setShow}
                                address={address?.data}
                                register={register}
                                formValues={formValues}
                                setValue={setValue}
                                errors={errors}
                            />
                        )} */}
                                {/* {activeStep === 3 && (
                            <CheckoutOrderSummary
                                handleNext={handleNext}
                                totalAmount={totalAmount}
                                items={items?.data}
                            />
                        )} */}
                                {activeStep === 2 && (
                                    <CheckoutPayment
                                        formValues={formValues}
                                        handleFormValueChange={
                                            handleFormValueChange
                                        }
                                        setActiveStep={setActiveStep}
                                        walletUsername={
                                            items.data?.walletUsername
                                        }
                                        totalAmount={
                                            totalAmount + shippingCharge
                                        }
                                        conversionFactor={
                                            items.data?.conversionFactor
                                        }
                                        handleSubmit={handleSubmit}
                                        repurchaseMutation={repurchaseMutation}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {/* mobile */}
                    {activeStep === 1 && items?.data && (
                        <div className="d-md-none d-block checkout-mobile">
                            {/* <div className="cart-table-header-cell cell-flex-3 text-start">
                            PRODUCTS
                        </div> */}
                            {items?.data?.cartList?.length > 0 && (
                                <>
                                    {items?.data?.cartList?.map((item) => (
                                        <div className="product-row">
                                            <div className="d-flex align-items-center">
                                                <div className="product-image">
                                                    <img
                                                        src={item?.image}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="product-detail-mobile text-start">
                                                    <h4 className="item-name">
                                                        {item?.name}
                                                    </h4>
                                                    <h6 className="item-code">
                                                        Item Code: {item?.code}
                                                    </h6>
                                                    <h6 className="item-price">
                                                        {`${
                                                            items.data
                                                                ?.conversionFactor
                                                                .symbolLeft
                                                        } ${CustomCurrencyConverter(
                                                            item.price,
                                                            items.data
                                                                ?.conversionFactor
                                                                ?.value
                                                        )}`}
                                                    </h6>
                                                    {/* ---Qty-- */}
                                                    <div className="qty-btn-parent">
                                                        <div className="cart-cell-qty-up">
                                                            <button
                                                                id="minus"
                                                                disabled={
                                                                    decrementCartMutation.status ===
                                                                    "loading"
                                                                }
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    handleQuantityChange(
                                                                        e,
                                                                        item?.packageId
                                                                    );
                                                                }}
                                                            >
                                                                -
                                                            </button>
                                                            <span>
                                                                {item?.quantity}
                                                            </span>
                                                            <button
                                                                id="plus"
                                                                disabled={
                                                                    addCartMutation.status ===
                                                                    "loading"
                                                                }
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    handleQuantityChange(
                                                                        e,
                                                                        item?.packageId
                                                                    );
                                                                }}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                        <div className="netamout mb-0">
                                                            {`${
                                                                items.data
                                                                    ?.conversionFactor
                                                                    .symbolLeft
                                                            } ${CustomCurrencyConverter(
                                                                item.price *
                                                                    item.quantity,
                                                                items.data
                                                                    ?.conversionFactor
                                                                    ?.value
                                                            )}`}
                                                        </div>
                                                        {/* <div className="gstamout">₹10</div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* --remove-- */}
                                            <div className="remove-product">
                                                <button
                                                    className="remove-product-btn"
                                                    onClick={() => {
                                                        handleRemoveCartItem(
                                                            item?.packageId
                                                        );
                                                    }}
                                                >
                                                    <i
                                                        className="fa-solid fa-xmark"
                                                        style={{
                                                            color: "rgb(140, 83, 49)",
                                                        }}
                                                    ></i>
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}

                            <div className="product-row-total mt-3">
                                <div className="container-fluid">
                                    <div className="net-amout-clac row">
                                        <div className="col-auto">
                                            SUB TOTAL
                                        </div>
                                        <div className="col-auto">
                                            <span className="amout">
                                                {" "}
                                                {`${
                                                    items.data?.conversionFactor
                                                        .symbolLeft
                                                } ${CustomCurrencyConverter(
                                                    Number(totalAmount),
                                                    items.data?.conversionFactor
                                                        ?.value
                                                )}`}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="net-amout-clac row">
                                        <div className="col-auto">SHIPPING</div>
                                        <div className="col-auto">
                                            <span className="amout">
                                                {`${
                                                    items.data?.conversionFactor
                                                        .symbolLeft
                                                } ${CustomCurrencyConverter(
                                                    Number(shippingCharge),
                                                    items.data?.conversionFactor
                                                        ?.value
                                                )}`}
                                            </span>
                                        </div>
                                    </div>
                                    {items.data?.userCountry === "India" && (
                                        <div className="net-amout-clac row">
                                            <div className="col-auto">
                                                PAYMENT GATEWAY FEE
                                            </div>
                                            <div className="col-auto">
                                                <span className="amout">
                                                    {`${
                                                        items.data
                                                            ?.conversionFactor
                                                            .symbolLeft
                                                    } ${CustomCurrencyConverter(
                                                        (Number(totalAmount) +
                                                            Number(
                                                                shippingCharge
                                                            )) *
                                                            pgFeePerc *
                                                            (1 + pgGSTPerc),
                                                        items.data
                                                            ?.conversionFactor
                                                            ?.value
                                                    )}`}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="net-amout-clac row">
                                        <div className="col-auto">
                                            <span className="text-bold-total">
                                                TOTAL
                                            </span>
                                        </div>
                                        <div className="col-auto">
                                            <span className="amout-bold">
                                                {`${
                                                    items.data?.conversionFactor
                                                        .symbolLeft
                                                } ${CustomCurrencyConverter(
                                                    (Number(totalAmount) +
                                                        Number(
                                                            shippingCharge
                                                        )) *
                                                        (1 +
                                                            pgFeePerc *
                                                                (1 +
                                                                    pgGSTPerc)),
                                                    items.data?.conversionFactor
                                                        ?.value
                                                )}`}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row checkout-moble">
                                        <div className="col-auto">
                                            <div
                                                className="continue-shop-moble"
                                                onClick={() => {
                                                    navigate(
                                                        `/shopping/${params?.type}/${params?.courier}`
                                                    );
                                                }}
                                            >
                                                CONTINUE SHOPPING
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div
                                                className="checkout-shop-moble"
                                                onClick={handleNext}
                                                disabled={isLoading}
                                            >
                                                {!isLoading
                                                    ? "PROCEED CHECKOUT"
                                                    : "SUBMITTING ..."}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* mobile-end */}
                </div>
            </div>
        </div>
    );
};

export default CheckoutLayout;
