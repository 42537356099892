import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
import CommonInput from "../Common/CommonInputComponent";
import { toast } from "react-toastify";
const ChangePassword = () => {
    const updateMutation = ApiHook.CallUpdatePassword();

    const [formValues, setValues] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const handleChange = (e, type) => {
        setValues((state) => ({
            ...state,
            [type]: e.target.value,
        }));
    };
    const isValid = () => {
        if (
            formValues?.oldPassword?.length > 3 &&
            formValues?.newPassword?.length > 3 &&
            formValues?.confirmPassword?.length > 3
        ) {
            if (formValues?.newPassword === formValues?.confirmPassword) {
                return true;
            }
            return false;
        }
        return false;
    };
    const handleSubmit = () => {
        if (isValid()) {
            updateMutation.mutate(formValues, {
                onSuccess: (res) => {
                    if (res.status) {
                        toast.success("Password Updated Successfully!!");
                        setValues({
                            oldPassword: "",
                            newPassword: "",
                            confirmPassword: "",
                        });
                    } else {
                        toast.error("Given Old Password is invalid");
                    }
                },
            });
        } else {
            toast.error("Old password and Confirm password are not same ");
        }
    };
    return (
        <div className="container-bg-design common-div-container">
            <div className="container-bg-design-container-flex layot-max-width">
                <div className="container-bg-design-container">
                    <div className="common-div-header gta-b">CHANGE PASSWORD</div>
                    <div className="change-password-form-container-flex">
                        <div className="change-password-form-container">
                            <CommonInput
                                label="OLD PASSWORD"
                                placeholder="Old Password"
                                onChange={(e) => {
                                    handleChange(e, "oldPassword");
                                }}
                                value={formValues?.oldPassword}
                                backgroundColor="#e5f0f2"
                            />
                            <CommonInput
                                label="NEW PASSWORD"
                                placeholder="New Password"
                                onChange={(e) => {
                                    handleChange(e, "newPassword");
                                }}
                                value={formValues?.newPassword}
                                backgroundColor="#e5f0f2"
                            />
                            <CommonInput
                                label="CONFIRM PASSWORD"
                                placeholder="Confirm Password"
                                onChange={(e) => {
                                    handleChange(e, "confirmPassword");
                                }}
                                value={formValues?.confirmPassword}
                                backgroundColor="#e5f0f2"
                            />
                            <button onClick={handleSubmit}>SUBMIT</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
