import { useMutation, useQuery } from "@tanstack/react-query";

import API from "../../api/api";
import { BASE_URL, DEFAULT_KEY } from "../../config/config";
import axios from "axios";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const GetTripForm = () => useQuery({
    queryKey: ["trip-form"],
    queryFn: () => callApi(`/get-trip-form`)
});

export const GetUploadedTripDocs = (handleSetTotalPages, currentPage, pageSize) => useQuery({
    queryKey: ["trip-docs", currentPage, pageSize],
    queryFn: () => callApi(`/get-uploaded-trip-docs?page=${currentPage}&perPage=${pageSize}`),
             onSuccess: (res) => {
                handleSetTotalPages(res.totalPages || 1);
             }
});

export const UploadTripFiles = () => useMutation({
    mutationFn: async (files) => {
        const formData = new FormData();

        [...files].forEach((file, i) => {
            formData.append(
                `file`,
                file.File || file,
                file.File.name || file.name
            );
            formData.append(`keyType[${i}]`, file.keyType || "");
        });

        // Create a new Axios instance for this specific request
        const customAxios = axios.create({
            baseURL: BASE_URL,
        });

        // Copy the api-key and access-token headers from the API instance to customAxios
        customAxios.defaults.headers.common["api-key"] = DEFAULT_KEY;
        customAxios.defaults.headers.common["access-token"] =
            localStorage.getItem("access-token") || "";

        // Set the "Content-Type" header to "multipart/form-data"
        customAxios.defaults.headers.common["Content-Type"] =
            "multipart/form-data";

        try {
            const response = await customAxios.post(
                `trip-files-upload`,
                formData
            );
            return response.data;
        } catch (error) {
            return error?.response?.data;
        }
    }
})