import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { ApiHook } from "../../hooks/apiHook";
import logo_user from "../../assests/images/invoiceLogo.png";

import CustomCurrencyConverter from "../../Currency/CustomCurrencyConverter";

const InvoiceModal = ({
    invoiceNo,
    type,
    closeButton = false,
    handleClose = () => {},
}) => {
    const { t } = useTranslation();

    const tableRef = useRef();
    const [conversionFactor, setConversionFactor] = useState();

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
        documentTitle: `Invoice-${invoiceNo}`,
        removeAfterPrint: true,
    });

    const invoice = ApiHook.CallRepurchaseInvoice(invoiceNo, type);

    useEffect(() => {
        setConversionFactor(
            invoice?.data?.userCountry?.toLowerCase() === "india"
                ? {
                      symbolLeft:
                          invoice?.data?.activeCurrencies["INR"]?.symbol,
                      value: invoice?.data?.activeCurrencies["INR"]?.value,
                  }
                : invoice?.data?.userCountry?.toLowerCase() === "bangladesh"
                ? {
                      symbolLeft:
                          invoice?.data?.activeCurrencies["BDT"]?.symbol,
                      value: invoice?.data?.activeCurrencies["BDT"]?.value,
                  }
                : {
                      symbolLeft:
                          invoice?.data?.activeCurrencies["USD"]?.symbol,
                      value: invoice?.data?.activeCurrencies["USD"]?.value,
                  }
        );
    }, [invoice?.data]);

    //---------------------------------- API ------------------------------------

    return (
        <div className="modal bs-example-modal-xl invoice-modal" ref={tableRef}>
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header justify-content-end">
                        <div className="d-flex gap-3">
                            <button
                                className="print-button_modal"
                                onClick={handlePrint}
                            >
                                <i
                                    className="fa fa-print"
                                    aria-hidden="true"
                                ></i>
                            </button>
                            {closeButton && (
                                <button
                                    className="print-button_modal"
                                    onClick={handleClose}
                                >
                                    <i
                                        className="fa-solid fa-xmark"
                                        aria-hidden="true"
                                    ></i>
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="d-flex justify-content-lg-start justify-content-center print-logo">
                                        <img
                                            className="modal_invoice-logo"
                                            src={logo_user}
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="text-lg-end text-center d-flex justify-content-lg-end justify-content-center print-incoice-head">
                                        <div
                                            style={{
                                                justifySelf: "flex-end",
                                            }}
                                        >
                                            <p className="heading_sm_tax-invoice">
                                                Original for Receipient
                                            </p>
                                            <h5 className="heading_tax-invoice mb-0">
                                                TAX INVOICE
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="row invoice_row">
                                <div className="col-lg-6">
                                    <div className="invoice_border-Box">
                                        <div className="invoice-items">
                                            <span className="invoice_text_strong d-block">
                                                {invoice?.data?.companyDetails
                                                    ?.name ??
                                                    "Moringo Organics Pvt Ltd"}
                                            </span>
                                            <span className="invoice_text_light d-block">
                                                {
                                                    invoice?.data
                                                        ?.companyDetails
                                                        ?.address
                                                }
                                            </span>
                                        </div>
                                        <div className="invoice-items">
                                            <span className="invoice_text_strong">
                                                GSTIN:{" "}
                                            </span>
                                            <span className="invoice_text_light">
                                                {
                                                    "33AAHCM8408N1Z3"
                                                    // invoice
                                                    //     ?.data
                                                    //     ?.companyDetails
                                                    //     ?.address
                                                }
                                            </span>
                                        </div>
                                        <div className="invoice-items">
                                            <span>
                                                <span className="invoice_text_strong">
                                                    State:
                                                </span>
                                                <span className="invoice_text_light">
                                                    {" "}
                                                    Tamil Nadu,
                                                </span>
                                            </span>
                                            <span>
                                                <span className="invoice_text_strong">
                                                    State Code:{" "}
                                                </span>
                                                <span className="invoice_text_light">
                                                    33
                                                </span>
                                            </span>
                                        </div>
                                        <div className="invoice-items">
                                            <span>
                                                <span className="invoice_text_strong">
                                                    CIN #:
                                                </span>
                                                <span className="invoice_text_light">
                                                    {"U51109KA2012PTC063338"}
                                                    <br />
                                                    {
                                                        invoice?.data
                                                            ?.companyDetails
                                                            ?.email
                                                    }
                                                    {
                                                        invoice?.data
                                                            ?.companyDetails
                                                            ?.phone
                                                    }
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="invoice_border-Box">
                                        <div className="invoice-items">
                                            <span className="invoice_text_strong">
                                                Order Date :{" "}
                                            </span>
                                            <span className="invoice_text_light">
                                                {invoice?.data?.date}
                                            </span>
                                        </div>
                                        <div className="invoice-items">
                                            <span className="invoice_text_strong">
                                                ORDER # :{" "}
                                            </span>
                                            <span className="invoice_text_light">
                                                {invoice?.data?.invoiceNo}
                                            </span>
                                        </div>
                                        <div className="invoice-items">
                                            <span className="invoice_text_strong">
                                                IBE # :
                                            </span>
                                            <span className="invoice_text_light">
                                                {invoice?.data?.userData?.ibeNo}
                                            </span>
                                        </div>
                                        <div className="invoice-items">
                                            <span className="invoice_text_strong">
                                                Pay Mode :{" "}
                                            </span>
                                            <span className="invoice_text_light">
                                                {
                                                    invoice?.data
                                                        ?.paymentDetails
                                                        ?.paymentMethod
                                                }
                                            </span>
                                        </div>
                                        <div className="invoice-items">
                                            <span className="invoice_text_strong">
                                                Ship Mode :{" "}
                                            </span>
                                            <span className="invoice_text_light">
                                                {invoice?.data?.deliveryMode}
                                            </span>
                                        </div>
                                        <div className="invoice-items">
                                            <span className="invoice_text_strong">
                                                Order Type :{" "}
                                            </span>
                                            <span className="invoice_text_light">
                                                {invoice?.data?.svType}
                                            </span>
                                        </div>
                                        <div className="invoice-items">
                                            <span className="invoice_text_strong">
                                                {/* Starter
                                    Pack
                                    Type
                                    :{" "} */}{" "}
                                            </span>
                                            <span className="invoice_text_light">
                                                {/* SCALE
                                    UP
                                    PACK */}{" "}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div>
                                        <h5 className="heading_invoice_box">
                                            BILL TO
                                        </h5>
                                        <div className="invoice_border-Box_with_head">
                                            <div className="invoice-items">
                                                <span className="invoice_text_strong d-block">
                                                    {`${invoice?.data?.userData?.UserDetail?.name} ${invoice?.data?.userData?.UserDetail?.secondName}`}
                                                </span>
                                                <span className="invoice_text_light d-block">
                                                    {`${invoice?.data?.userData?.UserDetail?.address}, ${invoice?.data?.userData?.UserDetail?.address2}`}
                                                </span>
                                            </div>
                                            <div className="invoice-items">
                                                <span className="invoice_text_strong">
                                                    <i
                                                        className="fa fa-mobile"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                                <span className="invoice_text_light">
                                                    {`+${invoice?.data?.userData?.UserDetail?.phoneCode} ${invoice?.data?.userData?.UserDetail?.mobile}`}
                                                </span>
                                            </div>
                                            <div className="invoice-items">
                                                <span className="invoice_text_strong">
                                                    <i
                                                        className="fa fa-envelope"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                                <span className="invoice_text_light">
                                                    {
                                                        invoice?.data?.userData
                                                            ?.email
                                                    }
                                                </span>
                                            </div>
                                            <div className="invoice-items">
                                                <span className="invoice_text_strong">
                                                    GST :
                                                </span>
                                                <span className="invoice_text_light">
                                                    NA
                                                </span>
                                            </div>
                                            <div className="invoice-items">
                                                <span className="invoice_text_strong">
                                                    State Code :
                                                </span>
                                                <span className="invoice_text_light">
                                                    NA
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div>
                                        <h5 className="heading_invoice_box">
                                            SHIP TO
                                        </h5>
                                        <div className="invoice_border-Box_with_head">
                                            <div className="invoice-items">
                                                <span className="invoice_text_strong d-block">
                                                    {`${invoice?.data?.userData?.UserDetail?.name} ${invoice?.data?.userData?.UserDetail?.secondName}`}
                                                </span>
                                                <span className="invoice_text_light d-block">
                                                    {`${invoice?.data?.userData?.UserDetail?.address}, ${invoice?.data?.userData?.UserDetail?.address2}`}
                                                </span>
                                            </div>
                                            <div className="invoice-items">
                                                <span className="invoice_text_strong">
                                                    <i
                                                        className="fa fa-mobile"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                                <span className="invoice_text_light">
                                                    {`+${invoice?.data?.userData?.UserDetail?.phoneCode} ${invoice?.data?.userData?.UserDetail?.mobile}`}
                                                </span>
                                            </div>
                                            <div className="invoice-items">
                                                <span className="invoice_text_strong">
                                                    <i
                                                        className="fa fa-envelope"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                                <span className="invoice_text_light">
                                                    {
                                                        invoice?.data?.userData
                                                            ?.email
                                                    }
                                                </span>
                                            </div>
                                            <div className="invoice-items">
                                                <span className="invoice_text_strong">
                                                    GST :
                                                </span>
                                                <span className="invoice_text_light">
                                                    NA
                                                </span>
                                            </div>
                                            <div className="invoice-items">
                                                <span className="invoice_text_strong">
                                                    State Code :
                                                </span>
                                                <span className="invoice_text_light">
                                                    NA
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="page-break"></div>
                        <div className="invoice_table_responsive_overflow">
                            <div className="modal_invoice_body_min_width">
                                <table className="w-100 table-invoice-calc">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Product Code</th>
                                            <th>Product Name</th>
                                            <th>HSN/SAC Code</th>
                                            <th>QTY</th>
                                            <th>
                                                Unit Price{" "}
                                                {conversionFactor?.symbolLeft}
                                            </th>
                                            <th>
                                                Total Amount{" "}
                                                {conversionFactor?.symbolLeft}
                                            </th>
                                            <th>Dis</th>
                                            <th>
                                                Taxable Value{" "}
                                                {conversionFactor?.symbolLeft}
                                            </th>
                                            <th
                                                colspan="2"
                                                className="text-center"
                                            >
                                                IGST
                                                <div className="table_head_border_top_colspan">
                                                    <span>Tax Rate</span>
                                                    <span className="middle_line"></span>
                                                    <span>
                                                        Tax Amount{" "}
                                                        {
                                                            conversionFactor?.symbolLeft
                                                        }
                                                    </span>
                                                </div>
                                            </th>
                                            <th>
                                                Total{" "}
                                                {conversionFactor?.symbolLeft}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoice?.data?.items?.map(
                                            (item, index) => (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.productId}</td>
                                                    <td>{item?.package}</td>
                                                    <td>
                                                        {item?.sacCode ?? "NA"}
                                                    </td>
                                                    <td>{item?.quantity}</td>
                                                    <td>
                                                        {CustomCurrencyConverter(
                                                            item?.price,
                                                            conversionFactor?.value
                                                        )}
                                                    </td>
                                                    <td>
                                                        {CustomCurrencyConverter(
                                                            item?.amount,
                                                            conversionFactor?.value
                                                        )}
                                                    </td>
                                                    <td>0.00</td>
                                                    <td>
                                                        {" "}
                                                        {CustomCurrencyConverter(
                                                            item?.amount,
                                                            conversionFactor?.value
                                                        )}
                                                    </td>
                                                    <td>{item?.gst}</td>
                                                    <td>
                                                        {CustomCurrencyConverter(
                                                            item?.tax,
                                                            conversionFactor?.value
                                                        )}
                                                    </td>
                                                    <td>
                                                        {CustomCurrencyConverter(
                                                            item?.totalAmount,
                                                            conversionFactor?.value
                                                        )}
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                        {/* {console.log(invoice?.data?.gst)} */}
                                        {invoice?.data?.deliveryMode ===
                                            "Courier" && (
                                            <tr>
                                                <td>
                                                    {invoice?.data?.items
                                                        ?.length + 1}
                                                </td>
                                                <td>{"SPI001"} </td>
                                                <td
                                                    // colspan="4"
                                                    className="text-center"
                                                >
                                                    Shipping Charge
                                                </td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    {CustomCurrencyConverter(
                                                        invoice?.data
                                                            ?.totalShippingCharge /
                                                            (1 +
                                                                invoice?.data
                                                                    ?.gst),
                                                        conversionFactor?.value
                                                    )}
                                                </td>
                                                <td>
                                                    {CustomCurrencyConverter(
                                                        invoice?.data
                                                            ?.totalShippingCharge /
                                                            (1 +
                                                                invoice?.data
                                                                    ?.gst),
                                                        conversionFactor?.value
                                                    )}
                                                </td>
                                                <td>0.00</td>
                                                <td>
                                                    {CustomCurrencyConverter(
                                                        invoice?.data
                                                            ?.totalShippingCharge /
                                                            (1 +
                                                                invoice?.data
                                                                    ?.gst),
                                                        conversionFactor?.value
                                                    )}
                                                </td>
                                                <td>
                                                    {invoice?.data?.gst * 100}{" "}
                                                </td>
                                                <td>
                                                    {CustomCurrencyConverter(
                                                        invoice?.data
                                                            ?.totalShippingCharge -
                                                            invoice?.data
                                                                ?.totalShippingCharge /
                                                                (1 +
                                                                    invoice
                                                                        ?.data
                                                                        ?.gst),
                                                        conversionFactor?.value
                                                    )}
                                                </td>

                                                <td>
                                                    {CustomCurrencyConverter(
                                                        invoice?.data
                                                            ?.totalShippingCharge,
                                                        conversionFactor?.value
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td
                                                colspan="4"
                                                className="text-center"
                                            >
                                                {invoice?.data?.userCountry?.toLowerCase() ===
                                                "india"
                                                    ? "Sub Total"
                                                    : "Total"}
                                            </td>
                                            <td>1</td>
                                            <td> </td>
                                            <td>
                                                {CustomCurrencyConverter(
                                                    Number(
                                                        invoice?.data?.subTotal
                                                    ) +
                                                        Number(
                                                            invoice?.data
                                                                ?.totalShippingCharge /
                                                                (1 +
                                                                    invoice
                                                                        ?.data
                                                                        ?.gst)
                                                        ),
                                                    conversionFactor?.value
                                                )}
                                            </td>
                                            <td>0.00</td>
                                            <td>
                                                {CustomCurrencyConverter(
                                                    Number(
                                                        invoice?.data?.subTotal
                                                    ) +
                                                        Number(
                                                            invoice?.data
                                                                ?.totalShippingCharge /
                                                                (1 +
                                                                    invoice
                                                                        ?.data
                                                                        ?.gst)
                                                        ),
                                                    conversionFactor?.value
                                                )}
                                            </td>
                                            <td> </td>
                                            <td>
                                                {CustomCurrencyConverter(
                                                    Number(
                                                        invoice?.data?.totalTax
                                                    ) +
                                                        Number(
                                                            invoice?.data
                                                                ?.totalShippingCharge -
                                                                invoice?.data
                                                                    ?.totalShippingCharge /
                                                                    (1 +
                                                                        invoice
                                                                            ?.data
                                                                            ?.gst)
                                                        ),
                                                    conversionFactor?.value
                                                )}
                                            </td>
                                            <td>
                                                {CustomCurrencyConverter(
                                                    Number(
                                                        invoice?.data
                                                            ?.totalAmount
                                                    ) +
                                                        Number(
                                                            invoice?.data
                                                                ?.totalShippingCharge
                                                        ),
                                                    conversionFactor?.value
                                                )}
                                            </td>
                                        </tr>

                                        {invoice?.data?.userCountry?.toLowerCase() ===
                                            "india" && (
                                            <>
                                                <tr>
                                                    <td>
                                                        {invoice?.data?.items
                                                            ?.length + 1}
                                                    </td>
                                                    <td>{"PGI001"} </td>
                                                    <td
                                                        // colspan="4"
                                                        className="text-center"
                                                    >
                                                        {
                                                            "Payment Gateway Charges (1.8%)"
                                                        }
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>
                                                        {CustomCurrencyConverter(
                                                            Number(
                                                                invoice?.data
                                                                    ?.paymentGatewayFee
                                                            ) /
                                                                (1 +
                                                                    Number(
                                                                        invoice
                                                                            ?.data
                                                                            ?.PaymentGatewayGSTPerc
                                                                    )),
                                                            conversionFactor?.value
                                                        )}
                                                    </td>
                                                    <td>
                                                        {CustomCurrencyConverter(
                                                            Number(
                                                                invoice?.data
                                                                    ?.paymentGatewayFee
                                                            ) /
                                                                (1 +
                                                                    Number(
                                                                        invoice
                                                                            ?.data
                                                                            ?.PaymentGatewayGSTPerc
                                                                    )),
                                                            conversionFactor?.value
                                                        )}
                                                    </td>
                                                    <td>0.00</td>
                                                    <td>
                                                        {CustomCurrencyConverter(
                                                            Number(
                                                                invoice?.data
                                                                    ?.paymentGatewayFee
                                                            ) /
                                                                (1 +
                                                                    Number(
                                                                        invoice
                                                                            ?.data
                                                                            ?.PaymentGatewayGSTPerc
                                                                    )),
                                                            conversionFactor?.value
                                                        )}
                                                    </td>
                                                    <td>
                                                        {invoice?.data
                                                            ?.PaymentGatewayGSTPerc *
                                                            100}{" "}
                                                    </td>
                                                    <td>
                                                        {CustomCurrencyConverter(
                                                            (Number(
                                                                invoice?.data
                                                                    ?.paymentGatewayFee
                                                            ) /
                                                                (1 +
                                                                    Number(
                                                                        invoice
                                                                            ?.data
                                                                            ?.PaymentGatewayGSTPerc
                                                                    ))) *
                                                                Number(
                                                                    invoice
                                                                        ?.data
                                                                        ?.PaymentGatewayGSTPerc
                                                                ),
                                                            conversionFactor?.value
                                                        )}
                                                    </td>

                                                    <td>
                                                        {CustomCurrencyConverter(
                                                            Number(
                                                                invoice?.data
                                                                    ?.paymentGatewayFee
                                                            ),
                                                            conversionFactor?.value
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        colspan="4"
                                                        className="text-center"
                                                    >
                                                        {"Total"}
                                                    </td>
                                                    <td>1</td>
                                                    <td> </td>
                                                    <td>
                                                        {CustomCurrencyConverter(
                                                            Number(
                                                                invoice?.data
                                                                    ?.subTotal
                                                            ) +
                                                                Number(
                                                                    invoice
                                                                        ?.data
                                                                        ?.totalShippingCharge /
                                                                        (1 +
                                                                            invoice
                                                                                ?.data
                                                                                ?.gst)
                                                                ) +
                                                                Number(
                                                                    invoice
                                                                        ?.data
                                                                        ?.paymentGatewayFee
                                                                ) /
                                                                    (1 +
                                                                        Number(
                                                                            invoice
                                                                                ?.data
                                                                                ?.PaymentGatewayGSTPerc
                                                                        )),
                                                            conversionFactor?.value
                                                        )}
                                                    </td>
                                                    <td>0.00</td>
                                                    <td>
                                                        {CustomCurrencyConverter(
                                                            Number(
                                                                invoice?.data
                                                                    ?.subTotal
                                                            ) +
                                                                Number(
                                                                    invoice
                                                                        ?.data
                                                                        ?.totalShippingCharge /
                                                                        (1 +
                                                                            invoice
                                                                                ?.data
                                                                                ?.gst)
                                                                ) +
                                                                Number(
                                                                    invoice
                                                                        ?.data
                                                                        ?.paymentGatewayFee
                                                                ) /
                                                                    (1 +
                                                                        Number(
                                                                            invoice
                                                                                ?.data
                                                                                ?.PaymentGatewayGSTPerc
                                                                        )),
                                                            conversionFactor?.value
                                                        )}
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {CustomCurrencyConverter(
                                                            Number(
                                                                invoice?.data
                                                                    ?.totalTax
                                                            ) +
                                                                Number(
                                                                    invoice
                                                                        ?.data
                                                                        ?.totalShippingCharge -
                                                                        invoice
                                                                            ?.data
                                                                            ?.totalShippingCharge /
                                                                            (1 +
                                                                                invoice
                                                                                    ?.data
                                                                                    ?.gst)
                                                                ) +
                                                                (Number(
                                                                    invoice
                                                                        ?.data
                                                                        ?.paymentGatewayFee
                                                                ) /
                                                                    (1 +
                                                                        Number(
                                                                            invoice
                                                                                ?.data
                                                                                ?.PaymentGatewayGSTPerc
                                                                        ))) *
                                                                    Number(
                                                                        invoice
                                                                            ?.data
                                                                            ?.PaymentGatewayGSTPerc
                                                                    ),
                                                            conversionFactor?.value
                                                        )}
                                                    </td>
                                                    <td>
                                                        {CustomCurrencyConverter(
                                                            Number(
                                                                invoice?.data
                                                                    ?.totalAmount
                                                            ) +
                                                                Number(
                                                                    invoice
                                                                        ?.data
                                                                        ?.totalShippingCharge
                                                                ) +
                                                                Number(
                                                                    invoice
                                                                        ?.data
                                                                        ?.paymentGatewayFee
                                                                ),
                                                            conversionFactor?.value
                                                        )}
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="">
                            <div className="mt-3">
                                <div className="row">
                                    <div className="col-lg-6">
                                        {/* <h6 className="total_invoice_amout_text">
                                                    Total Invoice amount in
                                                    words
                                                </h6>
                                                <p className="total_invoice_amout_text_sub">
                                                    one hundred fifty
                                                </p> */}
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="total_amout_table">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {`Total Amount before Tax (${conversionFactor?.symbolLeft})`}
                                                        </td>
                                                        <td>
                                                            {CustomCurrencyConverter(
                                                                Number(
                                                                    invoice
                                                                        ?.data
                                                                        ?.subTotal
                                                                ) +
                                                                    Number(
                                                                        invoice
                                                                            ?.data
                                                                            ?.totalShippingCharge /
                                                                            (1 +
                                                                                invoice
                                                                                    ?.data
                                                                                    ?.gst)
                                                                    ) +
                                                                    Number(
                                                                        invoice
                                                                            ?.data
                                                                            ?.paymentGatewayFee
                                                                    ) /
                                                                        (1 +
                                                                            Number(
                                                                                invoice
                                                                                    ?.data
                                                                                    ?.PaymentGatewayGSTPerc
                                                                            )),
                                                                conversionFactor?.value
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {`Add: IGST (${conversionFactor?.symbolLeft})`}
                                                        </td>
                                                        <td>
                                                            {CustomCurrencyConverter(
                                                                Number(
                                                                    invoice
                                                                        ?.data
                                                                        ?.totalTax
                                                                ) +
                                                                    Number(
                                                                        invoice
                                                                            ?.data
                                                                            ?.totalShippingCharge -
                                                                            invoice
                                                                                ?.data
                                                                                ?.totalShippingCharge /
                                                                                (1 +
                                                                                    invoice
                                                                                        ?.data
                                                                                        ?.gst)
                                                                    ) +
                                                                    (Number(
                                                                        invoice
                                                                            ?.data
                                                                            ?.paymentGatewayFee
                                                                    ) /
                                                                        (1 +
                                                                            Number(
                                                                                invoice
                                                                                    ?.data
                                                                                    ?.PaymentGatewayGSTPerc
                                                                            ))) *
                                                                        Number(
                                                                            invoice
                                                                                ?.data
                                                                                ?.PaymentGatewayGSTPerc
                                                                        ),
                                                                conversionFactor?.value
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Discount</td>
                                                        <td>00.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            {
                                                                "Total Amount after Tax (Round Up value)"
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                conversionFactor?.symbolLeft
                                                            }
                                                            {CustomCurrencyConverter(
                                                                Number(
                                                                    invoice
                                                                        ?.data
                                                                        ?.totalAmount
                                                                ) +
                                                                    Number(
                                                                        invoice
                                                                            ?.data
                                                                            ?.totalShippingCharge
                                                                    ) +
                                                                    Number(
                                                                        invoice
                                                                            ?.data
                                                                            ?.paymentGatewayFee
                                                                    ),
                                                                conversionFactor?.value
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2">
                                <p className="disclaimer">
                                    Ceritified that the particulars given above
                                    are true and correct. This is a system
                                    generated Invoice, doesn't require signature
                                    &amp; stamp.
                                </p>
                            </div>
                            <div className="mt-3">
                                <h6 className="invoice_text_strong">
                                    Important Note:
                                </h6>
                                <p className="disclaimer mt-0">
                                    For self-pickup orders, the Independent
                                    Brand Executive must pick up the products
                                    from the stock point within 15 days of the
                                    order date. If not collected within 15 days,
                                    the Independent Brand Executive cannot pick
                                    up the products from the stock point. In
                                    such cases, you can obtain the product
                                    directly from the company by paying the
                                    courier charges for the order within 30
                                    days.
                                </p>
                            </div>
                            <div className="mt-4">
                                <h4 className="tankyou_text text-center">
                                    Thank you for your business.
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceModal;
