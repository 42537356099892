import React from "react";
import { format } from 'date-fns';
import { useLocation } from "react-router";


const FooterSection = () => {
  const currentDate = new Date();
  const currentYear = format(currentDate, 'yyyy');
  const location = useLocation();

  return (
    <footer className={location.pathname === "/dashboard" ? "footer_copy" : "footer_copy custom-bg"}>
      <div className="container">
        <p>Copyright @ All right reserved. {currentYear}</p>
      </div>
    </footer>
  );
};

export default FooterSection;
