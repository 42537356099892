import React, { useState } from "react";
import CommonInput from "../Common/CommonInputComponent";
import { ApiHook } from "../../hooks/apiHook";
import SubmitButton from "../Common/buttons/SubmitButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { BankDetailsFormFields } from "../../utils/profileData";

const BankDetailsNew = ({ bankDetails }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [editInput, setEditInput] = useState(false);

  const [formValues, setFormValues] = useState({
    holderName: bankDetails.holderName ?? "",
    bankName: bankDetails.bankName ?? "",
    accountNo: bankDetails.accountNo ?? "",
    ifsc: bankDetails.ifsc ?? "",
  });

  const updateBankMutation = ApiHook.CallUpdateBankDetails(formValues);

  const handleChange = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setEditInput(true);
  };

  const handleSubmit = async () => {
    updateBankMutation.mutate(formValues, {
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ["profile"] });
        toast.success(res?.data);
        setEditInput(false);
      },
    });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="profile_sec-inputs row">
          <div className="col-12">
            <div className="d-flex justify-content-between mt-5">
              <h2>BANK DETAILS</h2>
            </div>
          </div>
          {BankDetailsFormFields.map((field, i) => (
            <div key={i} className="col-md-6">
              <CommonInput
                // disabled={!editInput ?? false}
                type={field.type}
                options={field.options}
                label={field.label}
                placeholder={field.placeholder}
                value={formValues[field.name] || ""}
                backgroundColor={"#e5f0f2"}
                onChange={(e) => handleChange(field.name, e.target.value)}
                disabled={true}
              />
            </div>
          ))}
        </div>
      </div>
      {editInput && (
        <div className={`paymenytLinkBtn ${editInput ? "disabled" : ""}`}>
          <SubmitButton
            className="btn"
            isSubmitting={!editInput}
            text={t("update")}
            click={handleSubmit}
          />
        </div>
      )}
    </>
  );
};

export default BankDetailsNew;
