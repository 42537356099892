import React, { useEffect, useState } from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CurrencyConverter from "../../Currency/CustomCurrencyConverter";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import ZohoSignComponent from "./ZohoSignComponent";

const RegisterTermsAndConditions = ({
    data,
    handleFormValueChange,
    formValues,
    handleNext,
}) => {
    const { t } = useTranslation();
    const [termsCheckError, setTermsCheckError] = useState(false);
    const getEsignLink = ApiHook.CallReplicaEsignLink();
    const [esignUrl, setEsignUrl] = useState("");

    const handleSubmit = async () => {
        // if (formValues?.termsAndCondtn) {
        if (true) {
            getEsignLink.mutate(
                {
                    username: formValues?.username,
                    email: formValues?.email,
                    panNumber: formValues?.pan,
                    fathersName: formValues?.surname,
                    nationality: formValues?.country,
                    fullName: formValues?.firstname + formValues?.lastname,
                },
                {
                    onSuccess: (res) => {
                        if (res?.status) {
                            setEsignUrl(res?.data);
                        } else {
                            setEsignUrl("");
                        }
                    },
                }
            );
        } else {
            setTermsCheckError(true);
        }
    };
    useEffect(() => {
        handleSubmit();
    }, []);

    return (
        <>
            {/* {esignUrl === "" && (
                <div className="register-terms-container">
                    <div className="register-terms-container-header">
                        <span className="gta-b">
                            IBE ELECTRONIC SIGNATURE VERBIAGE
                        </span>
                    </div>
                    <div className="register-terms-container-body">
                        {data && (
                            <div
                                className="register-terms-container-body-content"
                                dangerouslySetInnerHTML={{ __html: data }}
                            ></div>
                        )}
                    </div>
                    <div className="register-terms-container-footer">
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                handleFormValueChange(
                                    e.target.checked,
                                    "termsAndCondtn"
                                );
                                setTermsCheckError(false);
                            }}
                            style={
                                termsCheckError
                                    ? {
                                          outline: "red",
                                      }
                                    : {}
                            }
                        />
                        <span
                            style={
                                termsCheckError
                                    ? {
                                          color: "red",
                                      }
                                    : {}
                            }
                        >
                            I agree that I have read and understand the Terms
                            and Conditions and the Privacy Policies of the
                            Distributor agreement. By checking this box I submit
                            that I am over 18 years of age and that I am of
                            sound mind and judgement.
                        </span>
                    </div>
                    <button
                        className="common-button"
                        style={{ maxWidth: "500px" }}
                        onClick={handleSubmit}
                        // onClick={handleNext}
                        disabled={getEsignLink.status === "loading"}
                    >
                        {getEsignLink.status === "loading"
                            ? "SUBMITTING..."
                            : "SUBMIT"}
                    </button>
                </div>
            )} */}

            {/* {esignUrl !== "" && ( */}
            <ZohoSignComponent
                esignUrl={esignUrl}
                handleNext={handleNext}
                verifyDetails={{
                    username: formValues?.username,
                    email: formValues?.email,
                }}
            />
            {/* )} */}
        </>
    );
};

export default RegisterTermsAndConditions;
