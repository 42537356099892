import { useEffect, useRef, useState } from "react";

const CustomSelectBox = ({value, placeHolder, options, onSelect}) => {
  const [showOption, setShowOption] = useState(false);

  const reference = useRef(null);

  const handleSelectItem = (item) => {
    onSelect(item);
    setShowOption(false);
  };

  const handleToggleOptionView = () => {
    setShowOption(!showOption);
  };

  useEffect(() => {
    const handler = (event) => {
      if (!reference?.current?.contains(event.target)) {
        setShowOption(false);
      }
    };
    document.addEventListener("click", handler, true);

    return () => {
      document.removeEventListener("click", handler, true);
    };
  }, []);

  return (
    <div
      className="nominee-relationship square-border-cut"
      onClick={handleToggleOptionView}
      ref={reference}
    >
      <div style={{ paddingLeft: "15px" }}>
        {value && value.label ? (
          <span>{value.label}</span>
        ) : (
          <span style={{ color: "#7f7f80", fontWeight: "bold"/* , fontSize: "22px" */ }}>
            {placeHolder}
          </span>
        )}

        <i className="fa-solid fa-caret-down fa-sm" style={{ position: "absolute", right: "10px", top: "", color: "#9b5731", top: "50%" }}></i>
      </div>
      {showOption && (
        <div className="nominee-options">
          {options.map((item) => (
            <div key={item.value} onClick={() => handleSelectItem(item)}>
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelectBox;
