export const isANumber = (str) => {
    return /^[0-9]+$/.test(str) || str === "";
};
export const isAlphaOnly = (str) => {
    return /^[a-zA-Z ]*$/.test(str) || str === "";
};

export const isAlphanumeric = (str) => {
    return /^[a-zA-Z0-9 ]*$/.test(str) || str === "";
};

export const isMaxLength = (str, ln = 10) => {
    return str?.length <= ln;
};

export const isStringWithoutSpaces = (str) => {
    return /^[^\s]*$/.test(str) || str === "";
};

export const isAdult = (str) => {
    return validateAge(str) === true;
};

export const validateAge = (value) => {
    const today = new Date();
    const birthDate = new Date(value);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
        monthDiff < 0 ||
        (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
        age--;
    }

    return age >= 18 || "You must be at least 18 years old";
};
