import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
    GetTripForm,
    GetUploadedTripDocs,
    UploadTripFiles,
} from "../../services/trip/trip";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import CommonTableComponent from "../../components/Common/CommonTableComponent";
import TablePagination from "../../components/Common/table/TablePagination";
import moment from "moment/moment";
import CustomFileInput from "../../components/Common/CustomFileInput";
import pdfImage from "../../assests/images/pdf.png";

const Trip = () => {
    const START_PAGE = 1;

    const [files, setFiles] = useState(new Map());
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const queryClient = useQueryClient();

    const { t } = useTranslation();

    const handleSetTotalPages = (pageCount) => {
        setTotalPages(pageCount);
    };

    const form = GetTripForm();
    const upload = UploadTripFiles();
    const uploadedDocs = GetUploadedTripDocs(
        handleSetTotalPages,
        currentPage,
        itemsPerPage
    );

    const fileTemplate = (file) => {
        const fileName = file.resource.split(".");
        const fileType = fileName[fileName.length - 1].toLowerCase();

        switch (fileType) {
            case "jpg":
            case "png":
                return <i className="fa-regular fa-2x fa-image"></i>;
            case "pdf":
                return <img w="40px" height="30px" src={pdfImage}></img>;
            case "doc":
            case "docx":
                return <i className="fa-regular fa-2x fa-file-word"></i>;
            case "xls":
            case "xlsx":
                return <i className="fa-regular fa-2x fa-file-excel"></i>;
            default:
                return <i className="fa-regular fa-lg fa-file"></i>;
        }
    };

    const toNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };
    const toLastPage = () => {
        setCurrentPage(totalPages);
    };
    const toPreviousPage = () => {
        if (currentPage > START_PAGE) {
            setCurrentPage(currentPage - 1);
        }
    };
    const toFirstPage = () => {
        setCurrentPage(START_PAGE);
    };

    const handleDownloadMaterial = (item) => {
        if (!item.resource) {
            return;
        }
        const link = document.createElement("a");
        link.href = item.resource;
        link.download = item.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleUploadImage = () => {
        if (!files.size) return;

        let uploadFiles = [];

        for (const [key, value] of files) {
            value.forEach((file) => {
                uploadFiles.push({ File: file, keyType: key });
            });
        }

        upload.mutate(uploadFiles, {
            onSuccess: (res) => {
                if (res?.status) {
                    queryClient.invalidateQueries({ queryKey: ["trip-docs"] });
                    setFiles(new Map());
                    toast.success(t(res.data));
                } else {
                    if (res.data.code) {
                        toast.error(t(res?.data?.description));
                    } else {
                        toast.error(res?.message || "Error uploading files");
                    }
                }
            },
        });
    };

    const Meterial = ({ item }) => (
        <div
            onClick={() => handleDownloadMaterial(item)}
            style={{ position: "relative", cursor: "pointer" }}
            className="square-border-cut"
        >
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                    margin: "0 8px 0 20px",
                    justifyContent: "space-between",
                }}
            >
                <span>{item.name}</span>
                {fileTemplate(item)}
            </div>
        </div>
    );

    const statusColorLookUp=(el) => {
        if (el==="pending") {
            return({ backgroundColor: "#c7e6d3", color: "#66bc7e" })
        }
        if (el==="Rejected") {
            return({ backgroundColor: "#FEE4D1", color: "#9B5731" })
        }
        if (el==="APPROVED") {
            return({ backgroundColor: "#E4F0F2", color: "#2E8CA5" })
        }
    };

    const headers = [
        {
            label: "DATE",
            filter: "date",
            filterable: false,
            render: (el) => <span>{moment(el).format("YYYY/MM/DD")}</span>,
        },
        {
            label: "IBE #",
            filter: "ibe",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "NAME",
            filter: "ibeName",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "CITY",
            filter: "ibeCity",
            filterable: true,
            render: (el) => <span>{el || "NA"}</span>,
        },
        {
            label: "STATE",
            filter: "ibeState",
            filterable: true,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "UPLOADED FILES",
            filter: "fileType",
            filterable: false,
            render: (el) => <span>{el}</span>,
        },
        {
            label: "STATUS",
            filter: "status",
            filterable: false,
            render: (el) => {
                return (
                    <div className="custom-table-cell-without" style={statusColorLookUp(el)}>{el}</div>
                );
            },
        },
        {
            label: "REMARKS",
            filter: "ord_amt_inr",
            filterable: false,
            render: (el) => <span>{el || "-"}</span>,
        },
    ];

    const table = uploadedDocs?.data?.tripDocs && (
        <div className="d-flex justify-content-center">
        <div className="report-table-container-flex">
            <div className="report-table-container scrollbar-blue">
                <CommonTableComponent
                    headers={headers}
                    datas={uploadedDocs?.data?.tripDocs}
                    firstElementSticky={true}
                    searchOption={false}
                />
            </div>
        </div>
        </div>
    );

    const pagingation = uploadedDocs?.data?.tripDocs?.length ? (
        <div className="report-table-container-flex">
            <div className="pagination-container">
                <TablePagination
                    startPage={START_PAGE}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    itemsPerPage={itemsPerPage}
                    toNextPage={toNextPage}
                    toLastPage={toLastPage}
                    toPreviousPage={toPreviousPage}
                    toFirstPage={toFirstPage}
                    exportButton={false}
                />
            </div>
        </div>
    ) : null;

    return (
        <div className="container-bg-design common-div-container">
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    textWrap:"balance",
                    zIndex:"2",
                    position:"relative"
                }}
            >
                <h1 className="common-div-header gta-b">
                    UPLOAD EXOTIC TRIP DOCUMENTS
                </h1>

                <span>
                    Important Note: This Section is only to upload passport Scan
                    copy of passport if you are qualified for foreign trip.
                </span>

                {form?.data?.form?.length > 0 && (
                    <div
                        style={{
                            minWidth: "330px",
                            width: "30%",
                            marginTop: "40px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                        }}
                    >
                        {form?.data?.form.map((item) =>
                            item.type === "form" ? (
                                <div key={item.id}>
                                    <label className="custom-label">
                                        {t(item.name)}
                                    </label>
                                    <CustomFileInput
                                        id={item.id}
                                        item={item}
                                        files={files}
                                        setFiles={setFiles}
                                    />
                                </div>
                            ) : (
                                <Meterial key={item.id} item={item} />
                            )
                        )}

                        <span
                            style={{ textAlign: "center", marginBottom: "5px" }}
                            className="kyc-supported-formats"
                        >
                            *Supported formats: Jpeg, Pdf, Png/Maximum uploaded
                            file size: 2MB
                        </span>

                        <button
                            className="button-maxwidth"
                            onClick={handleUploadImage}
                        >
                            <span style={{ fontWeight: 700, color: "white" }}>
                                SUBMIT
                            </span>
                        </button>
                    </div>
                )}

                {/* TABLE */}
                <>
                    <div style={{ width: "90%", margin: "100px 0 20px 0" }}>
                        {table}
                    {pagingation}
                    </div>

                </>

                {/* NO DATA */}
                {!table && !form?.data?.form?.length && (
                    <div className="no-data-div">
                        <div className="no-data-div-image">
                            <img src="/images/nodata-image.png" alt="" />
                        </div>
                        <p>{t("noDataFound")}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Trip;
